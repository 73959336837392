import { createSlice } from "@reduxjs/toolkit";

import { MainPageThunk } from "../Thunk/MainPageThunk";
import { ChangeMainPageStatusThunk } from "../Thunk/ChangeMainPageStatusThunk";
import { UpdateMainPageThunk } from "../Thunk/UpdateMainPageThunk";
import { ShowMainPageThunk } from "../Thunk/ShowMainPageThunk";

let initState = {
	reload: false,
	mainPageData: [],
	currentPage: {},
	error: null,
};

const MainPageReducer = createSlice({
	name: "MainPage",

	initialState: initState,
	reducers: {},
	extraReducers: (builder) => {
		builder

			.addCase(MainPageThunk.pending, (state) => {
				state.reload = true;
			})
			.addCase(MainPageThunk.fulfilled, (state, action) => {
				state.reload = false;
				state.mainPageData = action.payload.data;
			})
			.addCase(MainPageThunk.rejected, (state, action) => {
				state.reload = false;
				state.error = action.payload;
			})
			/**   UpdateMainPageThunk */
			.addCase(UpdateMainPageThunk.pending, (state) => {
				state.reload = true;
			})
			.addCase(UpdateMainPageThunk.fulfilled, (state, action) => {
				state.reload = false;
				state.mainPageData = action.payload.data;
			})
			.addCase(UpdateMainPageThunk.rejected, (state, action) => {
				state.reload = false;
				state.error = action.payload;
			})
			/** ChangeMainPageStatusThunk */
			.addCase(ChangeMainPageStatusThunk.pending, (state) => {
				state.reload = true;
			})
			.addCase(ChangeMainPageStatusThunk.fulfilled, (state, action) => {
				state.reload = false;
				state.mainPageData = action.payload.data;
			})
			.addCase(ChangeMainPageStatusThunk.rejected, (state, action) => {
				state.reload = false;
				state.error = action.payload;
			})
			/** ShowMainPageThunk */
			.addCase(ShowMainPageThunk.pending, (state) => {
				state.reload = true;
			})
			.addCase(ShowMainPageThunk.fulfilled, (state, action) => {
				state.reload = false;
				state.currentPage = action.payload.data;
			})
			.addCase(ShowMainPageThunk.rejected, (state, action) => {
				state.reload = false;
				state.error = action.payload;
			});
	},
});
export default MainPageReducer.reducer;
