import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const AddNewPartnerThunk = createAsyncThunk(
	"Partners/AddNewPartnersThunk",
	async (arg, ThunkApi) => {
		const { rejectWithValue } = ThunkApi;

		try {
			const formData = new FormData();
			formData.append("link", "http://awd.com");
			if (arg?.logo instanceof File || arg?.logo instanceof Blob) {
				formData.append("logo", arg.logo);
			}

			const res = await axios.post(`api/admin/partners`, formData);

			return res.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.data);
		}
	}
);
