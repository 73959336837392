import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export let LogoutThunk = createAsyncThunk(
	"login/LogoutThunk",
	async (_, ThunkApi) => {
		let { rejectWithValue } = ThunkApi;
		let config = {
			method: "post",
			maxBodyLength: Infinity,
			url: `/auth/logout`,
			headers: {
				Accept: "application/vnd.api+json",
				Authorization: `Bearer ${localStorage.getItem("token")}`,
			},
		};
		try {
			let res = await axios.request(config);
			return res.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);
