import { createSlice } from "@reduxjs/toolkit";

import { OneSectorPageThunk } from "../Thunk/OneSectorPageThunk";
import { DeleteProjectThunk } from "../Thunk/DeleteProjectThunk";
import { AllSectorSolutionsThunk } from "../Thunk/AllSectorSolutionsThunk";
import { DeleteSectorSolutionThunk } from "../Thunk/DeleteSectorSolutionThunk";

const initState = {
	reload: true,
	sectorSolutionsData: [],
	currentPage: 1,
	lastPage: 1,
	currentPageData: null,
};

const SectorSolutionsReducer = createSlice({
	name: "Projects",

	initialState: initState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			// =======AllPagesThunk data===========
			.addCase(AllSectorSolutionsThunk.pending, (state, action) => {
				state.reload = true;
			})
			.addCase(AllSectorSolutionsThunk.fulfilled, (state, action) => {
				state.reload = false;
				state.sectorSolutionsData = action.payload?.data;

				state.currentPage = action.payload.meta.current_page;
				state.lastPage = action.payload.meta.last_page;
			})
			.addCase(AllSectorSolutionsThunk.rejected, (state, action) => {
				state.reload = false;
			})
			// =======OnePageThunk data===========
			.addCase(OneSectorPageThunk.pending, (state, action) => {
				state.reload = true;
			})
			.addCase(OneSectorPageThunk.fulfilled, (state, action) => {
				state.currentPageData = action.payload.data;
			})
			.addCase(OneSectorPageThunk.rejected, (state, action) => {
				state.reload = false;
			})

			.addCase(DeleteSectorSolutionThunk.pending, (state, action) => {})
			.addCase(DeleteSectorSolutionThunk.fulfilled, (state, action) => {})
			.addCase(DeleteSectorSolutionThunk.rejected, (state, action) => {});
	},
});

export default SectorSolutionsReducer.reducer;
