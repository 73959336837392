import { createSlice } from "@reduxjs/toolkit";
import { LoginThunk } from "../Thunk/LoginThunk";

let initState = {
	token: null,
};

let LoginReducer = createSlice({
	name: "login",

	initialState: initState,

	extraReducers: (builder) => {
		builder
			.addCase(LoginThunk.pending, (state, action) => {})
			.addCase(LoginThunk.fulfilled, (state, action) => {
				state.token = action.payload.data.token;
				localStorage.setItem("token", action.payload.data.token);
				localStorage.setItem("avatar", action.payload.data.avatar);
				localStorage.setItem("name", action.payload.data.name);

				// localStorage.setItem(
				// 	"permissions",
				// 	JSON.stringify(action.payload.data.permissions)
				// );
			})
			.addCase(LoginThunk.rejected, (state, action) => {
				localStorage.setItem("token", "");
			});
	},
});

export default LoginReducer.reducer;
