import { createSlice } from "@reduxjs/toolkit";

import { AllProjectsThunk } from "../Thunk/AllProjectsThunk";
import { OneProjectPageThunk } from "../Thunk/OnProjectPageThunk";
import { DeleteProjectThunk } from "../Thunk/DeleteProjectThunk";

const initState = {
	reload: true,
	projectsData: [],
	currentPage: 1,
	lastPage: 1,
	currentPageData: null,
};

const ProjectsReducer = createSlice({
	name: "Projects",

	initialState: initState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			// =======AllPagesThunk data===========
			.addCase(AllProjectsThunk.pending, (state, action) => {
				state.reload = true;
			})
			.addCase(AllProjectsThunk.fulfilled, (state, action) => {
				state.reload = false;
				state.projectsData = action.payload?.data;

				state.currentPage = action.payload.meta.current_page;
				state.lastPage = action.payload.meta.last_page;
			})
			.addCase(AllProjectsThunk.rejected, (state, action) => {
				state.reload = false;
			})
			// =======OnePageThunk data===========
			.addCase(OneProjectPageThunk.pending, (state, action) => {
				state.reload = true;
			})
			.addCase(OneProjectPageThunk.fulfilled, (state, action) => {
				state.currentPageData = action.payload.data;
			})
			.addCase(OneProjectPageThunk.rejected, (state, action) => {
				state.reload = false;
			})

			.addCase(DeleteProjectThunk.pending, (state, action) => {})
			.addCase(DeleteProjectThunk.fulfilled, (state, action) => {})
			.addCase(DeleteProjectThunk.rejected, (state, action) => {});
	},
});

export default ProjectsReducer.reducer;
