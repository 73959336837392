import React, { useEffect, useState } from "react";

import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { UserImage } from "../../../Assets/Images";
import { MdOutlineEmail } from "react-icons/md";
import { FaUserAlt, FaCloudUploadAlt } from "react-icons/fa";
import { VscSaveAs } from "react-icons/vsc";
import { IoMdPhotos } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";

import { useLocation } from "react-router-dom";
import { ProfileDataThunk } from "../../../RTK/Thunk/ProfileDataThunk";
import { ChangeProfileDataThunk } from "../../../RTK/Thunk/ChangeProfileDataThunk";
import Loader from "../../../Components/loader/Loader";

const ProfileData = () => {
	const location = useLocation();
	const pathName = location.pathname.slice(1);

	const dispatch = useDispatch();
	const { profileData, reload, error } = useSelector(
		(state) => state.ProfileDataReducer
	);

	/** get contact data */
	useEffect(() => {
		if (pathName === "edit-my-account") {
			dispatch(ProfileDataThunk());
		}
	}, [pathName]);

	const [imagePreview, setImagePreview] = useState(null);
	const [myAccountValues, setMyAccountValues] = useState({
		name: "",
		email: "",
		avatar: UserImage,
	});

	useEffect(() => {
		if (profileData) {
			setMyAccountValues({
				...myAccountValues,
				name: profileData?.name,
				email: profileData?.email,
				avatar: profileData?.avatar,
			});
		}
	}, [profileData]);

	// =====validation===========
	const schema = Yup.object().shape({
		name: Yup.string().required("مطلوب"),
		email: Yup.string().required("مطلوب"),
	});

	const {
		handleSubmit,
		register,
		reset,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
		mode: "all",
		defaultValues: {
			name: "",
			email: "",
		},
	});
	useEffect(() => {
		reset(myAccountValues);
	}, [myAccountValues, reset]);

	const fileInputRef = React.createRef();
	const handleButtonClick = (e) => {
		e.preventDefault();
		fileInputRef.current.click();
	};
	// to handle upload images files
	const handleUploadImages = (event) => {
		const file = event.target.files[0];
		setMyAccountValues({ ...myAccountValues, avatar: file });

		// Create a temporary URL for the selected file and set it as the imagePreview state
		const imageUrl = URL.createObjectURL(file);
		setImagePreview(imageUrl);
	};

	/** handle submit  */
	const onSubmit = async (data) => {
		try {
			// Dispatch the ChangeProfileDataThunk to update the profile data
			await dispatch(
				ChangeProfileDataThunk({ ...data, avatar: myAccountValues?.avatar })
			).unwrap();

			// Dispatch ProfileDataThunk to fetch the updated profile data
			dispatch(ProfileDataThunk());
		} catch (error) {
			// Handle errors here
			console.error("Error updating profile data:", error);
		}
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			{/* image  */}
			<section className='mb-10 '>
				<h6 className=' flex justify-start items-center gap-1 text-[#200407] text-[16px] font-normal mb-1 '>
					<IoMdPhotos className='w-6 h-6 text-main_color' />
					الصورة الشخصية
				</h6>

				<div className='w-52  mx-auto  '>
					<div className=' w-52  h-[200px] border-[1px] border-[#ddd] rounded-md p-1 mb-4 '>
						<div className='p-1 w-full h-full flex justify-center items-center'>
							{imagePreview ? (
								<img src={imagePreview} alt='' className='rounded-md h-full' />
							) : (
								<img
									src={myAccountValues?.avatar}
									alt=''
									className='rounded-md  h-full'
								/>
							)}
						</div>
					</div>

					<div className='w-full h-[48px] flex justify-center items-center bg-white border-[2px] border-dashed border-unnamed_color_119184  m-auto mb-1 cursor-pointer'>
						<input
							type='file'
							accept='image/*'
							onChange={handleUploadImages}
							ref={fileInputRef}
							/* hidden this input and use icon below to upload  */
							style={{ display: "none" }}
						/>
						{/* Button to trigger file input */}
						<button
							onClick={handleButtonClick}
							className='w-full h-full flex justify-center items-center'>
							<FaCloudUploadAlt
								className='w-6 h-6'
								title='تحديث صورة المستخدم'
							/>
						</button>
					</div>
					{error?.avatar && (
						<div className='text-[14px] text-[red] font-normal'>
							{error?.avatar}
						</div>
					)}
				</div>
			</section>

			{/* User name  */}
			<section className='mb-8'>
				<h4 className='mb-1 text-md font-normal'> اسم المستخدم</h4>
				<div
					className={`w-full h-14 border-[1px] border-gray-200 hover:border-second_color focus:border-second_color rounded-lg flex justify-start items-center gap-2 bg-white  px-5`}>
					<FaUserAlt className=' text-[20px] text-second_color' />
					<input
						type='text'
						name='name'
						{...register("name")}
						placeholder='أدخل اسم المستخدم '
						className='w-full h-full bg-transparent outline-none placeholder:text-gray-300 text-[15px] font-normal text-black'
					/>
				</div>

				{
					(error?.name && (
						<div className='text-[14px] text-[red] font-normal'>
							{error?.name}
						</div>
					),
					errors?.name && errors?.name.message ? (
						<div
							style={{
								width: "100%",
								color: "red",
								fontSize: "15px",
								marginTop: "5px",
							}}>
							{errors?.name.message}
						</div>
					) : null)
				}
			</section>

			{/* email  */}
			<section className='mb-8'>
				<h4 className='mb-1 text-md font-normal'> البريد الالكتروني</h4>
				<div
					className={`w-full h-14 border-[1px] border-gray-200 hover:border-second_color focus:border-second_color rounded-lg flex justify-start items-center gap-2 bg-white  px-5`}>
					<MdOutlineEmail className=' text-[20px] text-second_color' />
					<input
						type='email'
						name='email'
						{...register("email")}
						placeholder='أدخل البريد الالكتروني '
						className='w-full h-full bg-transparent outline-none placeholder:text-gray-300 text-[15px] font-normal text-black'
					/>
				</div>

				{
					(error?.email && (
						<div className='text-[14px] text-[red] font-normal'>
							{error?.email}
						</div>
					),
					errors?.email && errors?.email.message ? (
						<div
							style={{
								width: "100%",
								color: "red",
								fontSize: "15px",
								marginTop: "5px",
							}}>
							{errors?.email.message}
						</div>
					) : null)
				}
			</section>

			{/* Save Form Button  */}
			<section className='flex justify-start px-4'>
				<button
					type='submit'
					className='flex justify-center cursor-pointer items-center text-lg font-medium text-white px-10 h-14   bg-main_color hover:bg-second_color gap-2 rounded-md shadow-md'>
					<span className='text-white text-lg font-medium '>حفظ التعديلات</span>
					<span>
						<VscSaveAs />
					</span>
				</button>
			</section>
		</form>
	);
};

export default ProfileData;
