// import all icons

import { IoHome } from "react-icons/io5";
import { SiInformatica } from "react-icons/si";
import { GrTechnology } from "react-icons/gr";
import { TbServerCog } from "react-icons/tb";
import { BiLogoMicrosoftTeams } from "react-icons/bi";
import { HiUserGroup } from "react-icons/hi2";
import { RiCustomerService2Fill } from "react-icons/ri";
import { IoSettingsSharp } from "react-icons/io5";
import { FaMapMarkerAlt } from "react-icons/fa";
import { IoShareSocialSharp } from "react-icons/io5";

// dashboard routes and sections
export const dashboardPages = [
	{
		id: 1,
		navigateTo: "/",
		pageTitle: " الرئيسية ",
		icon: <IoHome />,
	},

	{
		id: 2,
		navigateTo: "/about-us",
		pageTitle: "من نحن ",
		icon: <SiInformatica />,
	},

	{
		id: 3,
		navigateTo: "/sector-solutions",
		pageTitle: "حلول القطاعات ",
		icon: <GrTechnology />,
	},
	{
		id: 4,
		navigateTo: "/services",
		pageTitle: "خدماتنا",
		icon: <TbServerCog />,
	},
	{
		id: 5,
		navigateTo: "/projects",
		pageTitle: "مشاريعنا",
		icon: <BiLogoMicrosoftTeams />,
	},
	{
		id: 6,
		navigateTo: "/partners",
		pageTitle: "شركائنا",
		icon: <HiUserGroup />,
	},

	{
		id: 7,
		navigateTo: "/contact-us",
		pageTitle: " تواصل معنا",
		icon: <RiCustomerService2Fill />,
	},

	{
		id: 8,
		navigateTo: "/address-setting",
		pageTitle: "العناوين وطرق التواصل",
		icon: <FaMapMarkerAlt />,
	},

	{
		id: 9,
		navigateTo: "/social-media-accounts",
		pageTitle: "صفحات التواصل الاجتماعي ",
		icon: <IoShareSocialSharp />,
	},

	{
		id: 10,
		navigateTo: "/setting",
		pageTitle: "إعدات الموقع",
		icon: <IoSettingsSharp />,
	},
];
