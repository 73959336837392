import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const AddressThunk = createAsyncThunk(
	"Address/AddressThunk",
	async (_, ThunkApi) => {
		let { rejectWithValue } = ThunkApi;
		try {
			let res = await axios.get(`admin/settings`);

			return res.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.data);
		}
	}
);
