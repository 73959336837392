import React, { useEffect, useState } from "react";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { ChangePasswordThunk } from "../../../RTK/Thunk/ChangePasswordThunk";

import { FaEyeSlash } from "react-icons/fa";
import { IoIosEye } from "react-icons/io";
import { VpnKey } from "@mui/icons-material";
import { VscSaveAs } from "react-icons/vsc";
import Loader from "../../../Components/loader/Loader";

const ChangePassword = () => {
	const dispatch = useDispatch();
	let { reload, error } = useSelector((state) => state.ChangePasswordReducer);

	const [old_password, setOld_password] = useState("");
	const [showCurrentPassword, setShowCurrentPassword] = useState(false);
	// -----------------------------------------------------------------
	const [new_password, setNew_password] = useState("");
	const [showNewPassword, setShowNewPassword] = useState(false);

	// -----------------------------------------------------------------
	const [new_password_confirmation, setNew_password_confirmation] =
		useState("");
	const [showConformPassword, setShowConformPassword] = useState(false);
	// ======================================================================

	// handleSaveNewPassword
	const handleSaveNewPassword = () => {
		const payload = {
			old_password: old_password,
			new_password: new_password,
			new_password_confirmation: new_password_confirmation,
		};

		dispatch(ChangePasswordThunk(payload));
	};

	useEffect(() => {
		if (reload) {
			setOld_password("");
			setNew_password("");
			setNew_password_confirmation("");
		}
	}, [reload]);

	return reload ? (
		<Loader />
	) : (
		<section>
			{/* current password */}
			<section className='mb-8'>
				<h4 className='mb-1 text-md font-normal'>كلمة المرور الحالية</h4>
				<div
					className={`w-full h-14 border-[1px] border-gray-200 hover:border-second_color focus:border-second_color rounded-lg flex justify-start items-center gap-2 bg-white  px-5`}>
					<VpnKey className=' text-[20px] text-second_color' />
					<input
						type={showCurrentPassword ? "text" : "password"}
						name='old_password'
						value={old_password}
						onChange={(e) => setOld_password(e.target.value)}
						placeholder='••••••••'
						className='w-full h-full bg-transparent outline-none placeholder:text-gray-300 text-[15px] font-normal text-black'
					/>

					<div className=' w-[25px] h-[25px] flex justify-center items-center'>
						{showCurrentPassword ? (
							<IoIosEye
								className='cursor-pointer w-6 h-6 open-eye'
								onClick={() => setShowCurrentPassword(!showCurrentPassword)}
							/>
						) : (
							<FaEyeSlash
								className='cursor-pointer'
								onClick={() => setShowCurrentPassword(!showCurrentPassword)}
							/>
						)}
					</div>
				</div>
				{error?.old_password && (
					<div className='text-[14px] text-[red] font-normal'>
						{error?.old_password}
					</div>
				)}
			</section>

			{/* new password */}
			<section className='mb-8'>
				<h4 className='mb-1 text-md font-normal'>كلمة المرور الجديدة</h4>
				<div
					className={`w-full h-14 border-[1px] border-gray-200 hover:border-second_color focus:border-second_color rounded-lg flex justify-start items-center gap-2 bg-white  px-5`}>
					<VpnKey className=' text-[20px] text-second_color' />
					<input
						type={showNewPassword ? "text" : "password"}
						name='new_password'
						value={new_password}
						onChange={(e) => setNew_password(e.target.value)}
						placeholder='••••••••'
						className='w-full h-full bg-transparent outline-none placeholder:text-gray-300 text-[15px] font-normal text-black'
					/>

					<div className=' w-[25px] h-[25px] flex justify-center items-center'>
						{showNewPassword ? (
							<IoIosEye
								className='cursor-pointer w-6 h-6 open-eye'
								onClick={() => setShowNewPassword(!showNewPassword)}
							/>
						) : (
							<FaEyeSlash
								className='cursor-pointer'
								onClick={() => setShowNewPassword(!showNewPassword)}
							/>
						)}
					</div>
				</div>
				{error?.new_password && (
					<div className='text-[14px] text-[red] font-normal'>
						{error?.new_password}
					</div>
				)}
			</section>

			{/* confirm new  password */}
			<section className='mb-8'>
				<h4 className='mb-1 text-md font-normal'>
					{" "}
					تأكيد كلمة المرور الجديدة{" "}
				</h4>
				<div
					className={`w-full h-14 border-[1px] border-gray-200 hover:border-second_color focus:border-second_color rounded-lg flex justify-start items-center gap-2 bg-white  px-5`}>
					<VpnKey className=' text-[20px] text-second_color' />
					<input
						type={showConformPassword ? "text" : "password"}
						name='new_password_confirmation'
						value={new_password_confirmation}
						onChange={(e) => setNew_password_confirmation(e.target.value)}
						className='w-full h-full bg-transparent outline-none placeholder:text-gray-300 text-[15px] font-normal text-black'
					/>

					<div className=' w-[25px] h-[25px] flex justify-center items-center'>
						{showConformPassword ? (
							<IoIosEye
								className='cursor-pointer w-6 h-6 open-eye'
								onClick={() => setShowConformPassword(!showConformPassword)}
							/>
						) : (
							<FaEyeSlash
								className='cursor-pointer'
								onClick={() => setShowConformPassword(!showConformPassword)}
							/>
						)}
					</div>
				</div>
				{error?.new_password_confirmation && (
					<div className='text-[14px] text-[red] font-normal'>
						{error?.new_password_confirmation}
					</div>
				)}
				{new_password !== "" &&
					new_password_confirmation !== "" &&
					new_password !== new_password_confirmation && (
						<div className='text-[14px] text-[red] font-normal'>
							كلمة المرور غير متطابقة!
						</div>
					)}
			</section>

			{/* Save Form Button  */}
			<section className='flex justify-start px-4'>
				<button
					disabled={
						!old_password && !new_password && !new_password_confirmation
					}
					onClick={handleSaveNewPassword}
					className='flex disabled:cursor-not-allowed justify-center cursor-pointer items-center text-lg font-medium text-white px-10 h-14   bg-main_color hover:bg-second_color gap-2 rounded-md shadow-md'>
					<span className='text-white text-lg font-medium '>حفظ التعديلات</span>
					<span>
						<VscSaveAs />
					</span>
				</button>
			</section>
		</section>
	);
};

export default ChangePassword;
