import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import { BiLogoMicrosoftTeams } from "react-icons/bi";

import { FiEdit } from "react-icons/fi";

import { PageHeader, SwitchBox } from "../../../Components/index";
import { useLocation, useNavigate } from "react-router-dom";
import EditHomeSectionContent from "./EditHomeSectionContent";
import { useDispatch, useSelector } from "react-redux";
import { MainPageThunk } from "../../../RTK/Thunk/MainPageThunk";
import { changeTapLanguage } from "../../../RTK/Reducers/LanguageTapsReducer";
import { Loader } from "../../../Components/loader";

const HomePage = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const pathName = location.pathname.slice(1);

	const { mainPageData, reload } = useSelector(
		(state) => state.MainPageReducer
	);

	/** get contact data */
	useEffect(() => {
		if (pathName === "") {
			dispatch(MainPageThunk());
		}
	}, [pathName]);

	return (
		<>
			<Helmet>
				<title>لوحة تحكم راج | الرئيسية</title>
			</Helmet>
			<section>
				<section>
					<PageHeader
						currentPage={null}
						description={
							"من خلال هذه الصفحة يمكنك التحكم في أقسام الصفحه الرئيسية للموقع  مثل تعديل المحتوي أو اخفاء الأقسام "
						}
					/>
				</section>
				{reload ? (
					<section className=' h-full flex justify-center items-center pt-40'>
						<Loader />
					</section>
				) : (
					<section className='grid md:grid-cols-3 grid-cols-1 gap-6'>
						{mainPageData?.map((item, idx) => (
							<section
								key={idx}
								className=' rounded-md shadow-md border-[1px] border-slate-200 '>
								<div className='flex flex-col justify-center items-center py-6'>
									<div key={idx} className='mb-2'>
										<BiLogoMicrosoftTeams className='w-12 h-12 text-main_color' />
									</div>
									<h3 className=' text-xl font-normal text-second_color'>
										{item?.title}
									</h3>
								</div>
								<div className=' bg-section_bg flex justify-center items-center gap-4 py-4 border-t-[1px] border-t-slate-200'>
									<SwitchBox status={item?.status} MainPageId={item?.id} />
									<FiEdit
										onClick={() => {
											navigate(`/edit-home-section/${item?.id}`);
											dispatch(changeTapLanguage("arabic"));
										}}
										title='تعديل'
										className='w-5 h-5'
										style={{ color: "#faa726", cursor: "pointer" }}
									/>
								</div>
							</section>
						))}
					</section>
				)}
			</section>

			{/* Edit Home page section content*/}
			{pathName === "edit-home-section" && <EditHomeSectionContent />}
		</>
	);
};

export default HomePage;
