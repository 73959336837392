import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export let ShowMainPageThunk = createAsyncThunk(
	"MainPage/ShowMainPageThunk",
	async (arg, ThunkApi) => {
		let { rejectWithValue } = ThunkApi;
		try {
			let res = await axios.get(`/api/admin/main_pages/${arg.id}`);

			return res.data;
		} catch (error) {
			return rejectWithValue(error.response.data.data);
		}
	}
);
