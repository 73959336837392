import React from "react";
import { AddPageContent, PageHeader } from "../../../Components";
import { Helmet } from "react-helmet";

const AboutUs = () => {
	return (
		<>
			<Helmet>
				<title>لوحة تحكم راج | من نحن</title>
			</Helmet>

			<section className=''>
				<PageHeader
					currentPage={"من نحن"}
					parentPage={null}
					isNested={true}
					description={"بامكانك التحكم في محتوي قسم من نحن من خلال هذه الصفحه"}
				/>
				{/* Page Content */}
				<AddPageContent />
			</section>
		</>
	);
};

export default AboutUs;
