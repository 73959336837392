import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

/** Redux */
import { Store } from "./RTK/Store";
import { Provider } from "react-redux";

// Context
import TextEditorProvider from "./Context/TextEditorProvider";

/** Axios */
import "./API/axios-global";
import AxiosInterceptors from "./API/AxiosInterceptors";

/** Toast CSS Style */
import "react-toastify/dist/ReactToastify.css";

// Css Styles
import "./index.css";

/** Pages */
import { PageNotFound, RootLayout } from "./Pages";
import { LoginPage } from "./Pages/login";

/** Dashboard Pages  */
import HomePage from "./Pages/Dashboard/HomePage/HomePage";
import {
	AboutUs,
	AddNewPartners,
	AddNewProjects,
	AddNewSector,
	AddNewService,
	AddressSetting,
	ContactUs,
	EditHomeSectionContent,
	EditMyAccount,
	EditPartner,
	EditProject,
	EditSectorSolution,
	EditService,
	MessageDetailsModal,
	Partners,
	Projects,
	SectorSolutions,
	Services,
	Setting,
	SocialMediaAccounts,
} from "./Pages/Dashboard";

const router = createBrowserRouter([
	/* login page */
	{
		path: "login",
		element: <LoginPage />,
	},

	/** Main page */
	{
		path: "/",
		element: (
			<AxiosInterceptors>
				<RootLayout />
			</AxiosInterceptors>
		),
		errorElement: <PageNotFound />,

		children: [
			// THS IS THE HOME PAGE OF DASHBOARD
			{
				index: true,
				element: <HomePage />,
			},
			/**--------------------------------------------------------------------------- */

			// About us
			{ path: "about-us", element: <AboutUs /> },
			/**--------------------------------------------------------------------------- */

			// SectorSolutions
			{ path: "sector-solutions", element: <SectorSolutions /> },
			/**--------------------------------------------------------------------------- */

			// create-new-sector
			{ path: "create-new-sector", element: <AddNewSector /> },

			{ path: "edit-sector/:id", element: <EditSectorSolution /> },

			/**--------------------------------------------------------------------------- */
			// Services
			{ path: "services", element: <Services /> },
			// add new Service
			{ path: "add-new-service", element: <AddNewService /> },
			{ path: "edit-service/:id", element: <EditService /> },

			/**--------------------------------------------------------------------------- */
			// Projects
			{ path: "projects", element: <Projects /> },
			// add new Projects
			{ path: "add-new-project", element: <AddNewProjects /> },
			{ path: "edit-project/:id", element: <EditProject /> },

			/**--------------------------------------------------------------------------- */
			// Partners
			{ path: "partners", element: <Partners /> },
			// add new partners
			{ path: "add-new-partner", element: <AddNewPartners /> },
			{ path: "edit-partner/:id", element: <EditPartner /> },

			/**--------------------------------------------------------------------------- */
			// Edit Home Section Content
			{ path: "edit-home-section/:id", element: <EditHomeSectionContent /> },

			/**--------------------------------------------------------------------------- */
			// edit my account
			{ path: "edit-my-account", element: <EditMyAccount /> },

			/**--------------------------------------------------------------------------- */
			// ContactUs
			{ path: "contact-us", element: <ContactUs /> },

			// MessageDetailsModal
			{ path: "message-details", element: <MessageDetailsModal /> },

			/**--------------------------------------------------------------------------- */
			// AddressSetting
			{ path: "address-setting", element: <AddressSetting /> },

			/**--------------------------------------------------------------------------- */
			//social-media-accounts
			{ path: "social-media-accounts", element: <SocialMediaAccounts /> },
			/**--------------------------------------------------------------------------- */

			// Setting
			{ path: "setting", element: <Setting /> },

			/**--------------------------------------------------------------------------- */
		],
	},
]);

ReactDOM.createRoot(document.getElementById("root")).render(
	<Provider store={Store}>
		<TextEditorProvider>
			<React.StrictMode>
				<RouterProvider router={router} />
			</React.StrictMode>
		</TextEditorProvider>
	</Provider>
);
