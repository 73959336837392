import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export let MainPageThunk = createAsyncThunk(
	"MainPage/MainPageThunk",
	async (_, ThunkApi) => {
		let { rejectWithValue } = ThunkApi;
		try {
			let url = `api/admin/main_pages`;

			let res = await axios.get(url);

			return res.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);
