import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const UpdateMainPageThunk = createAsyncThunk(
	"MainPage/UpdateMainPageThunk",
	async (arg, ThunkApi) => {
		const { rejectWithValue } = ThunkApi;

		try {
			const res = await axios.put(`/api/admin/main_pages/${arg.id}`, arg);

			return res.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.data);
		}
	}
);
