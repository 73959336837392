import { createSlice } from "@reduxjs/toolkit";
import { AboutUsThunk } from "../Thunk/AboutUsThunk";
import { UpdateAboutUsDataThunk } from "../Thunk/UpdateAboutUsDataThunk";

let initState = {
	reload: false,
	aboutUsData: {},
	error: null,
};

const AboutUsReducer = createSlice({
	name: "AboutUs",

	initialState: initState,
	reducers: {},
	extraReducers: (builder) => {
		builder

			.addCase(UpdateAboutUsDataThunk.pending, (state) => {
				state.reload = true;
			})
			.addCase(UpdateAboutUsDataThunk.fulfilled, (state, action) => {
				state.reload = false;
				state.aboutUsData = action.payload.data;
			})
			.addCase(UpdateAboutUsDataThunk.rejected, (state, action) => {
				state.reload = false;
				state.error = action.payload;
			})

			.addCase(AboutUsThunk.pending, (state) => {
				state.reload = true;
			})
			.addCase(AboutUsThunk.fulfilled, (state, action) => {
				state.reload = false;
				state.aboutUsData = action.payload.data;
			})
			.addCase(AboutUsThunk.rejected, (state, action) => {
				state.reload = false;
				state.error = action.error.message;
			});
	},
});
export default AboutUsReducer.reducer;
