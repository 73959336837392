import { createSlice } from "@reduxjs/toolkit";
import { UpdateAddressThunk } from "../Thunk/UpdateAddressThunk";
import { AddressThunk } from "../Thunk/AddressThunk";

let initState = {
	reload: false,
	addressData: {},
	error: null,
};

let AddressReducer = createSlice({
	name: "Address",

	initialState: initState,
	reducers: {},
	extraReducers: (builder) => {
		builder

			.addCase(UpdateAddressThunk.pending, (state) => {
				state.reload = true;
			})
			.addCase(UpdateAddressThunk.fulfilled, (state, action) => {
				state.reload = false;
				state.addressData = action.payload.data;
			})
			.addCase(UpdateAddressThunk.rejected, (state, action) => {
				state.reload = false;
				state.error = action.payload;
			})

			.addCase(AddressThunk.pending, (state) => {
				state.reload = true;
			})
			.addCase(AddressThunk.fulfilled, (state, action) => {
				state.reload = false;
				state.addressData = action.payload.data;
			})
			.addCase(AddressThunk.rejected, (state, action) => {
				state.reload = false;
				state.error = action.error.message;
			});
	},
});
export default AddressReducer.reducer;
