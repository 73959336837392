import * as React from "react";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";

import { Link, useNavigate } from "react-router-dom";
import { FaHome } from "react-icons/fa";

const PagesBreadcrumbs = ({ currentPage, parentPage, route }) => {
	const navigate = useNavigate();

	return (
		<>
			<div className='mb-4 '>
				<Breadcrumbs className='flex  items-center' aria-label='breadcrumb'>
					<Link
						className='text-[16px] flex  items-center font-medium text-main_color '
						sx={{ display: "flex", alignItems: "center" }}
						to={"/"}>
						<span
							onClick={() => {
								navigate("/");
							}}
							className='w-9 h-9 ml-1  rounded-full bg-main_color flex justify-center items-center cursor-pointer text-white'>
							<FaHome fontSize='inherit' />
						</span>
						الرئيسية
					</Link>
					{parentPage && (
						<Link
							className='text-[16px] cursor-pointer  font-normal  text-slate-900 '
							to={route}>
							{parentPage}
						</Link>
					)}

					<Typography className='text-[16px] font-normal  text-slate-500 '>
						{currentPage}
					</Typography>
				</Breadcrumbs>
			</div>
		</>
	);
};

export default PagesBreadcrumbs;
