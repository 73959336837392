import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const UpdateSiteLogoThunk = createAsyncThunk(
	"SiteLogo/UpdateSiteLogoThunk",
	async (arg, ThunkApi) => {
		const { rejectWithValue } = ThunkApi;

		try {
			const formData = new FormData();

			if (
				(arg?.site_logo instanceof File || arg?.site_logo instanceof Blob) &&
				(arg?.footer_logo instanceof File ||
					arg?.footer_logo instanceof Blob) &&
				(arg?.slider_image instanceof File || arg?.slider_image instanceof Blob)
			) {
				formData.append("site_logo", arg.site_logo);
				formData.append("footer_logo", arg.footer_logo);
				formData.append("slider_image", arg.slider_image);
			}

			const res = await axios.post(`admin/site_logo`, formData);

			return res.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.data);
		}
	}
);
