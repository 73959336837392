import React, { useState } from "react";

export const TextEditorContext = React.createContext({});

const TextEditorProvider = (props) => {
	const [editorValueAr, setEditorValueAr] = useState("");
	const [editorValueEn, setEditorValueEn] = useState("");

	const editorContent = {
		editorValueAr,
		setEditorValueAr,

		editorValueEn,
		setEditorValueEn,
	};

	return (
		<TextEditorContext.Provider value={editorContent}>
			{props.children}
		</TextEditorContext.Provider>
	);
};

export default TextEditorProvider;
