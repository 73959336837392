import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const UpdateAddressThunk = createAsyncThunk(
	"Address/UpdateAddressThunk",
	async (arg, ThunkApi) => {
		const { rejectWithValue } = ThunkApi;

		try {
			const res = await axios.put(`admin/settings`, arg);

			return res.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.data);
		}
	}
);
