import React from "react";
import { IoMdInformationCircleOutline } from "react-icons/io";

const PageDescription = ({ description }) => {
	return (
		<section className=' flex justify-start items-center gap-2 rounded-lg mb-8 bg-blue-100 text-[#0b4aa0] py-3 px-4'>
			<IoMdInformationCircleOutline />
			<span className='text-md font-normal'>{description}</span>
		</section>
	);
};

export default PageDescription;
