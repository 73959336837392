import axios from "axios";
import { toast } from "react-toastify";

axios.defaults.baseURL = "https://api.raj.com.sa/";

axios.interceptors.request.use(
	(req) => {
		if (!req.url.includes("login")) {
			let token = localStorage.getItem("token") || null;
			if (token !== null) {
				req.headers.Authorization = `Bearer ${token}`;
				req.headers.Locale = "ar";
			}
		}

		return req;
	},
	(error) => {
		return Promise.reject(error);
	}
);

axios.interceptors.response.use((success) => {
	if (
		(success?.status === 200 &&
			["delete", "patch", "post", "pull", "put"].includes(
				success?.config?.method
			)) ||
		(success?.status === 201 &&
			["delete", "patch", "post", "pull", "put"].includes(
				success?.config?.method
			))
	) {
		toast.success(success?.data?.message, {
			theme: "light",
		});
	}
	return success;
});
