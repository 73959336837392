import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export let AllServicesThunk = createAsyncThunk(
	"Services/AllPagesThunk",
	async (arg, ThunkApi) => {
		console.log(arg?.page);
		let { rejectWithValue } = ThunkApi;
		try {
			let url = `api/admin/services?page=${arg.page}&per_page=10`;

			let res = await axios.get(url);

			return res.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);
