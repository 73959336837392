import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export let ChangePasswordThunk = createAsyncThunk(
	"ProfileData/changePasswordThunk",
	async (arg, ThunkApi) => {
		let { rejectWithValue } = ThunkApi;
		try {
			let res = await axios.put(`/auth/password/change_password`, {
				old_password: arg?.old_password,
				new_password: arg?.new_password,
				new_password_confirmation: arg?.new_password_confirmation,
			});

			return res.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.data);
		}
	}
);
