import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export let DeleteSectorSolutionThunk = createAsyncThunk(
	"SectorSolution/DeleteSectorSolutionThunk",
	async (arg, ThunkApi) => {
		let { rejectWithValue } = ThunkApi;
		try {
			let res = await axios.delete(`api/admin/sector_solutions/${arg?.id}`);

			return res.data;
		} catch (error) {
			// console.log(error.response.data);
			return rejectWithValue(error.response.data);
		}
	}
);
