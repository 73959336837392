import React, { useContext } from "react";

import PagesBreadcrumbs from "../PagesBreadcrumbs/PagesBreadcrumbs";
import PageDescription from "../PageDescription/PageDescription";
import { PiNewspaperBold } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { changeTapLanguage } from "../../RTK/Reducers/LanguageTapsReducer";
import { TextEditorContext } from "../../Context/TextEditorProvider";

const PageHeader = ({
	currentPage,
	parentPage,
	isNested,
	route,
	description,
	addBtn,
	navigateTo,
}) => {
	const navigate = useNavigate();
	/* to handle reset the tap language to arabic */
	const dispatch = useDispatch();

	// To get the editor content
	const editorContent = useContext(TextEditorContext);
	const {
		setEditorValueAr,

		setEditorValueEn,
	} = editorContent;

	const resetsMessage = () => {
		setEditorValueAr("");
		setEditorValueEn("");
	};

	return (
		<>
			{/* Pages Bread crumbs */}
			<section className='flex justify-between items-center mb-6'>
				<PagesBreadcrumbs
					currentPage={currentPage}
					parentPage={parentPage}
					isNested={isNested}
					route={route}
				/>
				{addBtn && (
					<button
						onClick={() => {
							navigate(`${navigateTo}`);
							dispatch(changeTapLanguage("arabic"));
							resetsMessage();
						}}
						className='flex justify-center items-center text-lg font-normal text-white px-8 h-14  mr-auto bg-main_color hover:bg-second_color gap-2 rounded-md shadow-md '>
						<span>{addBtn}</span>
						<PiNewspaperBold />
					</button>
				)}
			</section>

			{/* PageDescription */}
			<PageDescription description={description} />
		</>
	);
};

export default PageHeader;
