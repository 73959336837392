import React from "react";
import { Helmet } from "react-helmet";

import { PageHeader } from "../../../Components";

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import ChangePassword from "./ChangePassword";
import ProfileData from "./ProfileData";

// handle according
const Accordion = styled((props) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	backgroundColor: "transparent",
	"&:not(:last-child)": {
		marginBottom: "0",
	},
	"&:before": {
		display: "none",
	},
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
	({ theme }) => ({
		width: "100%",
		backgroundColor: "#ffc368",

		marginBottom: "0",
		flexDirection: "row",
		minHeight: "58px",
		border: "1px solid #f1f8fb",
		borderRadius: " 6px",

		"& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
			transform: "rotate(-90deg)",
		},
		"& .MuiAccordionSummary-content": {
			marginLeft: theme.spacing(1),
		},
	})
);

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: "35px 12px 25px 12px",
	backgroundColor: "#f1f8fb",
	border: "1px solid #ffc368",
	borderTop: "none",
	borderRadius: " 6px",
}));

const EditMyAccount = () => {
	// change profile data
	const [expanded2, setExpanded2] = React.useState("panel2");
	const handleChange2 = (panel2) => (event, newExpanded2) => {
		setExpanded2(newExpanded2 ? panel2 : false);
	};
	// change password according
	const [expanded, setExpanded] = React.useState("");
	const handleChange = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};
	// --------------------------------------------------------

	return (
		<>
			<Helmet>
				<title>لوحة التحكم | حسابي</title>
			</Helmet>

			<div className='fixed top-[65px] left-0 z-50 w-full h-full min-h-ful overflow-y-auto pb-20 pt-5 hidden_body_scroll bg-section_bg '>
				<div className='w-[60%] m-auto py-4 '>
					{/* Pages Bread crumbs */}
					<section>
						<PageHeader
							currentPage={"تعديل بيانات حسابي"}
							parentPage={null}
							isNested={true}
							description={
								"يمكنك تعديل اسم المستخدم والبريد الالكتروني  وكلمه المرور  وتغير الصوره الشخصيه لحسابك"
							}
						/>
					</section>

					<section className='w-full  '>
						{/* Social Media Accounts  */}
						<section className='w-full mb-5'>
							<Accordion
								expanded={expanded2 === "panel2"}
								onChange={handleChange2("panel2")}>
								<AccordionSummary
									aria-controls='panel1d-content'
									id='panel1d-header'
									expandIcon={
										<ArrowForwardIosSharpIcon
											sx={{
												fontSize: "1rem",
												color: "#315ca7",
												transform: "rotate(180deg)",
											}}
										/>
									}>
									<Typography
										sx={{
											fontSize: "18px",
											fontWeight: "500",
											fontFamily: "Tajawal",
											color: "#315ca7",
										}}>
										تعديل بيانات الحساب
									</Typography>
								</AccordionSummary>
								<AccordionDetails>
									{/* Change Password Section UI Component */}
									<ProfileData />
								</AccordionDetails>
							</Accordion>
						</section>
						{/* Change password */}
						<section className='w-full '>
							<Accordion
								expanded={expanded === "panel1"}
								onChange={handleChange("panel1")}>
								<AccordionSummary
									aria-controls='panel1d-content'
									id='panel1d-header'
									expandIcon={
										<ArrowForwardIosSharpIcon
											sx={{
												fontSize: "1rem",
												color: "#315ca7",
												transform: "rotate(180deg)",
											}}
										/>
									}>
									<Typography
										sx={{
											fontSize: "18px",
											fontWeight: "500",
											fontFamily: "Tajawal",
											color: "#315ca7",
										}}>
										تغيير كلمة المرور
									</Typography>
								</AccordionSummary>
								<AccordionDetails>
									{/* Change Password Section UI Component */}
									<ChangePassword />
								</AccordionDetails>
							</Accordion>
						</section>
					</section>
				</div>
			</div>
		</>
	);
};

export default EditMyAccount;
