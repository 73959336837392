import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useDispatch, useSelector } from "react-redux";
import { changeTapLanguage } from "../../RTK/Reducers/LanguageTapsReducer";
import { FormLabel } from "@mui/material";

import { FaLanguage } from "react-icons/fa6";

export default function LanguagesTaps() {
	const { tapLanguage } = useSelector((state) => state.LanguageTapsReducer);
	const dispatch = useDispatch();

	const handleChangeTap = (e) => {
		dispatch(changeTapLanguage(e.target.value));
	};

	return (
		<FormControl className='mb-4 '>
			<FormLabel className='text-lg flex justify-start items-center gap-2 font-normal text-main_color '>
				<FaLanguage className=' text-second_color w-5' />
				حدد لغة المحتوي الخاص بالصفحة
			</FormLabel>
			<RadioGroup
				className='-mr-[28px]'
				row
				value={tapLanguage}
				onChange={handleChangeTap}>
				<FormControlLabel value='arabic' control={<Radio />} label='عربي' />
				<FormControlLabel value='english' control={<Radio />} label='انجليزي' />
			</RadioGroup>
		</FormControl>
	);
}
