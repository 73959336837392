import React, { useEffect, useState } from "react";
import { PageHeader } from "../../../Components";
import { Helmet } from "react-helmet";

// icons

import { IoMdPhotos } from "react-icons/io";
import { FaCloudUploadAlt } from "react-icons/fa";
import { VscSaveAs } from "react-icons/vsc";
import { DummyImage } from "../../../Assets/Images";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { SiteLogoThunk } from "../../../RTK/Thunk/SiteLogoThunk";
import { UpdateSiteLogoThunk } from "../../../RTK/Thunk/UpdateSiteLogoThunk";

const Setting = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const dispatch = useDispatch(false);
	const pathName = location.pathname.slice(1);

	const { SiteLogoData, error } = useSelector((state) => state.SiteLogoReducer);

	const [footerLogoPreview, setFooterLogoPreview] = useState(null);
	const [footerLogo, setFooterLogo] = useState(DummyImage);
	const [mainLogoPreview, setMainLogoPreview] = useState(null);
	const [mainLogo, setMainLogo] = useState(DummyImage);

	const [heroSectionImagePreview, setHeroSectionImagePreview] = useState(null);
	const [heroSectionImage, setHeroSectionImage] = useState(DummyImage);

	/** get contact data */
	useEffect(() => {
		if (pathName === "setting") {
			dispatch(SiteLogoThunk());
		}
	}, [pathName]);

	useEffect(() => {
		if (SiteLogoData) {
			setMainLogo(SiteLogoData?.site_logo);
			setFooterLogo(SiteLogoData?.footer_logo);
			setHeroSectionImage(SiteLogoData?.slider_image);
		}
	}, [SiteLogoData]);

	//MAIN logo
	const fileInputRef = React.createRef();
	const handleButtonClick = () => {
		fileInputRef.current.click();
	};

	const handleUploadImages = (event) => {
		const file = event.target.files[0];

		setMainLogo(file);

		// Create a temporary URL for the selected file and set it as the mainLogoPreview state
		const imageUrl = URL.createObjectURL(file);
		setMainLogoPreview(imageUrl);
	};

	/*---------------------------------------------*/
	// footer logo
	const footerLogoRef = React.createRef();
	// 2- Trigger a click event on the hidden file input when the button is clicked
	const handleFooterLogoClick = () => {
		footerLogoRef.current.click();
	};

	// to handle upload images files
	const handleUploadFooterLogo = (event) => {
		const file = event.target.files[0];

		setFooterLogo(file);

		// Create a temporary URL for the selected file and set it as the mainLogoPreview state
		const imageUrl = URL.createObjectURL(file);
		setFooterLogoPreview(imageUrl);
	};

	/*---------------------------------------------*/
	// footer logo
	const heroSectionImageRef = React.createRef();
	// 2- Trigger a click event on the hidden file input when the button is clicked
	const handleHeroSectionImageClick = () => {
		heroSectionImageRef.current.click();
	};

	// to handle upload images files
	const handleUploadHeroSectionImage = (event) => {
		const file = event.target.files[0];

		setHeroSectionImage(file);

		// Create a temporary URL for the selected file and set it as the mainLogoPreview state
		const imageUrl = URL.createObjectURL(file);
		setHeroSectionImagePreview(imageUrl);
	};

	/** handle submit  */
	const handleEditSite = async () => {
		try {
			await dispatch(
				UpdateSiteLogoThunk({
					site_logo: mainLogo,
					footer_logo: footerLogo,
					slider_image: heroSectionImage,
				})
			)
				.unwrap()
				.then((data) => {
					navigate("/setting");
				});
		} catch (error) {
			// Handle errors here
			console.error("Error updating profile data:", error);
		}
	};

	return (
		<>
			<Helmet>
				<title>لوحة تحكم راج | اعدادت الموقع </title>
			</Helmet>

			<section className=''>
				<PageHeader
					currentPage={"اعدادت الموقع"}
					parentPage={null}
					description={
						"بامكانك التحكم في تغيير شعار الموقع الرئيسي وشعار الفوتر من خلال هذا القسم"
					}
				/>

				<section className='w-full'>
					{/* main logo  */}
					<h4 className='flex justify-start items-center text-main_color gap-1  text-lg font-medium '>
						<IoMdPhotos className='w-5 text-second_color' />
						تغيير الشعار الاساسي
					</h4>

					<section className='border-[1px] border-slate-100 shadow-lg rounded-md p-8 mb-12 '>
						{/* IMAGE PREVIEW */}
						<div className=' w-[40%] h-[220px] mx-auto border-[1px] border-[#ddd] rounded-md p-5 mb-4 '>
							<div className='p-1 w-full h-full flex justify-center items-center'>
								{mainLogoPreview ? (
									<img
										src={mainLogoPreview}
										alt=''
										className='rounded-md h-full'
									/>
								) : (
									<img src={mainLogo} alt='' className='rounded-md h-full' />
								)}
							</div>
						</div>

						<div className='w-[40%] h-[48px] flex justify-center items-center bg-white border-[2px] border-dashed border-second_color   m-auto mb-4 cursor-pointer'>
							<input
								type='file'
								accept='image/*'
								onChange={handleUploadImages}
								ref={fileInputRef}
								/* hidden this input and use icon below to upload  */
								style={{ display: "none" }}
							/>

							{/* Button to trigger file input */}
							<button
								onClick={handleButtonClick}
								className='w-full h-full flex justify-center items-center'>
								<FaCloudUploadAlt className='w-6 h-6' />
							</button>

							{error?.site_logo && (
								<div className='text-[14px] text-[red] font-normal'>
									{error?.site_logo}
								</div>
							)}
						</div>
					</section>

					{/* footer logo  */}
					<h4 className='flex justify-start items-center text-main_color gap-1  text-lg font-medium '>
						<IoMdPhotos className='w-5 text-second_color' />
						تغيير شعار الفوتر
					</h4>

					<section className='border-[1px] border-slate-100 shadow-lg rounded-md p-8  mb-5'>
						{/* IMAGE PREVIEW */}
						<div className=' w-[40%] p-5 h-[200px] mx-auto border-[1px] border-[#ddd] bg-slate-200 rounded-md  mb-4 '>
							<div className='p-1 w-full h-full flex justify-center items-center'>
								{footerLogoPreview ? (
									<img
										src={footerLogoPreview}
										alt=''
										className='rounded-md h-full'
									/>
								) : (
									<img src={footerLogo} alt='' className='rounded-md h-full ' />
								)}
							</div>
						</div>

						<div className='w-[40%]  h-[48px] flex justify-center items-center bg-white border-[2px] border-dashed  border-second_color  m-auto mb-4 cursor-pointer'>
							<input
								type='file'
								accept='image/*'
								onChange={handleUploadFooterLogo}
								ref={footerLogoRef}
								/* hidden this input and use icon below to upload  */
								style={{ display: "none" }}
							/>

							{/* Button to trigger file input */}
							<button
								onClick={handleFooterLogoClick}
								className='w-full h-full flex justify-center items-center'>
								<FaCloudUploadAlt className='w-6 h-6' title='تحديث الشعار' />
							</button>

							{error?.footer_logo && (
								<div className='text-[14px] text-[red] font-normal'>
									{error?.footer_logo}
								</div>
							)}
						</div>
					</section>

					{/* hero Section Image */}
					<h4 className='flex justify-start items-center text-main_color gap-1  text-lg font-medium '>
						<IoMdPhotos className='w-5 text-second_color' />
						تغيير صورة القسم العلوي للموقع
					</h4>

					<section className='border-[1px] border-slate-100 shadow-lg rounded-md p-8  mb-5'>
						{/* IMAGE PREVIEW */}
						<div className=' w-[40%] p-5 h-[200px] mx-auto border-[1px] border-[#ddd] bg-white rounded-md  mb-4 '>
							<div className='p-1 w-full h-full flex justify-center items-center'>
								{heroSectionImagePreview ? (
									<img
										src={heroSectionImagePreview}
										alt=''
										className='rounded-md h-full'
									/>
								) : (
									<img
										src={heroSectionImage}
										alt=''
										className='rounded-md h-full '
									/>
								)}
							</div>
						</div>

						<div className='w-[40%]  h-[48px] flex justify-center items-center bg-white border-[2px] border-dashed  border-second_color  m-auto mb-4 cursor-pointer'>
							<input
								type='file'
								accept='image/*'
								onChange={handleUploadHeroSectionImage}
								ref={heroSectionImageRef}
								style={{ display: "none" }}
							/>

							{/* Button to trigger file input */}
							<button
								onClick={handleHeroSectionImageClick}
								className='w-full h-full flex justify-center items-center'>
								<FaCloudUploadAlt className='w-6 h-6' title='تحديث الصوره' />
							</button>

							{error?.slider_image && (
								<div className='text-[14px] text-[red] font-normal'>
									{error?.slider_image}
								</div>
							)}
						</div>
					</section>

					<section>
						<button
							onClick={handleEditSite}
							className='flex justify-center items-center text-lg font-medium mx-auto text-white px-8 h-14   bg-main_color hover:bg-second_color gap-2 rounded-md shadow-md '>
							<span> حفظ الاعدادات </span>
							<VscSaveAs />
						</button>
					</section>
				</section>
				{/* Page Content */}
			</section>
		</>
	);
};

export default Setting;
