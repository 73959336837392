import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

export default function Loader() {
	return (
		<Box
			className='h-full'
			sx={{ display: "flex", justifyContent: "center", alignItems: " center" }}>
			<CircularProgress sx={{ color: "#faa726" }} />
		</Box>
	);
}
