import { configureStore } from "@reduxjs/toolkit";

import LoginReducer from "./Reducers/LoginReducer";
import AddressReducer from "./Reducers/AddressReducer";
import AboutUsReducer from "./Reducers/AboutUsReducer";
import MainPageReducer from "./Reducers/MainPageReducer";
import ProjectsReducer from "./Reducers/ProjectsReducer";
import ServicesReducer from "./Reducers/ServicesReducer";
import SiteLogoReducer from "./Reducers/SiteLogoReducer";
import PartnersReducer from "./Reducers/PartnersReducer";
import ContactUsReducer from "./Reducers/ContactUsReducer";
import SocialLinksReducer from "./Reducers/SocialLinksReducer";
import ProfileDataReducer from "./Reducers/ProfileDataReducer";
import LanguageTapsReducer from "./Reducers/LanguageTapsReducer";
import ChangePasswordReducer from "./Reducers/ChangePasswordReducer";
import SectorSolutionsReducer from "./Reducers/SectorSolutionsReducer";

export let Store = configureStore({
	reducer: {
		LoginReducer,
		AboutUsReducer,
		AddressReducer,
		ServicesReducer,
		ProjectsReducer,
		SiteLogoReducer,
		PartnersReducer,
		MainPageReducer,
		ContactUsReducer,
		SocialLinksReducer,
		ProfileDataReducer,
		LanguageTapsReducer,
		ChangePasswordReducer,
		SectorSolutionsReducer,
	},
});
