import React, { useEffect, useState } from "react";

import { Helmet } from "react-helmet";
import { LanguagesTaps, PageHeader } from "../../../Components";

import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// Icons
import { FaSquarePhoneFlip } from "react-icons/fa6";
import { VscSaveAs } from "react-icons/vsc";
import { FaMapMarkerAlt } from "react-icons/fa";
import { MdOutlineMarkEmailRead } from "react-icons/md";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AddressThunk } from "../../../RTK/Thunk/AddressThunk";
import { UpdateAddressThunk } from "../../../RTK/Thunk/UpdateAddressThunk";
import { Loader } from "../../../Components/loader";

const AddressSetting = () => {
	const location = useLocation();
	const pathName = location.pathname.slice(1);
	const dispatch = useDispatch();

	const { addressData, reload } = useSelector((state) => state.AddressReducer);
	const { tapLanguage } = useSelector((state) => state.LanguageTapsReducer);

	/** get contact data */
	useEffect(() => {
		if (pathName === "address-setting") {
			dispatch(AddressThunk());
		}
	}, [pathName]);

	const [content, setContent] = useState({
		main_address: {
			ar: "",
			en: "",
		},
		sub_address: {
			ar: "",
			en: "",
		},

		phone: "",
		email: "",
		sales_email: "",
	});
	// =====validation===========
	const schema = Yup.object().shape({
		main_address: Yup.object().shape({
			ar: Yup.string().required("العنوان مطلوب"),
			en: Yup.string().required("Title is required"),
		}),

		sub_address: Yup.object().shape({
			ar: Yup.string().required("العنوان مطلوب"),
			en: Yup.string().required("Title is required"),
		}),

		phone: Yup.string().required("مطلوب"),
		email: Yup.string().required("مطلوب"),
		sales_email: Yup.string().required("مطلوب"),
	});

	const {
		handleSubmit,
		register,
		reset,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
		mode: "all",
		defaultValues: {
			main_address: {
				ar: "",
				en: "",
			},
			sub_address: {
				ar: "",
				en: "",
			},

			phone: "",
			email: "",
			sales_email: "",
		},
	});

	useEffect(() => {
		if (addressData) {
			setContent({
				...content,
				main_address: {
					ar: addressData?.main_address?.ar,
					en: addressData?.main_address?.en,
				},
				sub_address: {
					ar: addressData?.sub_address?.ar,
					en: addressData?.sub_address?.ar,
				},

				phone: addressData?.phone,
				email: addressData?.email,
				sales_email: addressData?.sales_email,
			});
		}
	}, [addressData]);

	useEffect(() => {
		reset(content);
	}, [content, reset]);

	/** handle submit  */
	const onSubmit = async (data) => {
		try {
			await dispatch(UpdateAddressThunk(data)).unwrap();

			dispatch(AddressThunk());
		} catch (error) {
			console.error("Error updating profile data:", error);
		}
	};

	return (
		<>
			<Helmet>
				<title>لوحة تحكم راج | العناوين وطرق التواصل</title>
			</Helmet>

			<section className=''>
				<PageHeader
					currentPage={"العناوين وطرق التواصل"}
					parentPage={null}
					isNested={true}
					description={
						"بامكانك التحكم في العناوين وأرقم وطرق التواصل من خلال هذه الصفحه"
					}
				/>
				{reload ? (
					<section className=' h-full flex justify-center items-center pt-40'>
						<Loader />
					</section>
				) : (
					<form className='w-full' onSubmit={handleSubmit(onSubmit)}>
						{/* change languages taps */}
						<LanguagesTaps />

						{/* Address  */}
						<h4 className='flex justify-start items-center text-main_color gap-1 mb-2 text-lg font-medium '>
							<FaMapMarkerAlt className='w-5  text-main_color' />
							العناوين
						</h4>

						<section className='mb-12 border-[1px] border-slate-100 shadow-lg rounded-md p-8 '>
							<section className='mb-8'>
								<h4 className='mb-1 text-md font-normal'>العنوان الرئيسي</h4>

								{tapLanguage === "arabic" ? (
									<div
										className={`w-full h-14 border-[1px] border-gray-200 hover:border-second_color focus:border-second_color rounded-lg flex justify-start items-center gap-2 bg-white  px-5`}>
										<FaMapMarkerAlt className=' text-[20px] text-second_color opacity-60' />
										<input
											type='text'
											name='main_address.ar'
											{...register("main_address.ar")}
											placeholder='أدخل عنوان الرئيسي'
											className='w-full h-full bg-transparent outline-none placeholder:text-gray-300 text-[15px] font-normal text-black'
										/>
									</div>
								) : (
									<div
										className={`w-full h-14 border-[1px] border-gray-200 hover:border-second_color focus:border-second_color rounded-lg flex justify-start items-center gap-2 bg-white  px-5`}>
										<input
											type='text'
											dir='ltr'
											name='main_address.en'
											{...register("main_address.en")}
											placeholder='أدخل عنوان الرئيسي'
											className='w-full h-full bg-transparent outline-none placeholder:text-gray-300 text-[15px] font-normal text-black'
										/>
										<FaMapMarkerAlt className=' text-[20px] text-second_color opacity-60' />
									</div>
								)}

								{errors?.main_address && errors?.main_address.message ? (
									<div
										style={{
											width: "100%",
											color: "red",
											fontSize: "15px",
											marginTop: "5px",
										}}>
										{errors?.main_address.message}
									</div>
								) : null}
							</section>
							<section className='mb-8'>
								<h4 className='mb-1 text-md font-normal'>العنوان الفرعي</h4>

								{tapLanguage === "arabic" ? (
									<div
										className={`w-full h-14 border-[1px] border-gray-200 hover:border-second_color focus:border-second_color rounded-lg flex justify-start items-center gap-2 bg-white  px-5`}>
										<FaMapMarkerAlt className=' text-[20px] text-second_color opacity-60' />
										<input
											type='text'
											name='sub_address.ar'
											{...register("sub_address.ar")}
											placeholder='أدخل عنوان الفرعي'
											className='w-full h-full bg-transparent outline-none placeholder:text-gray-300 text-[15px] font-normal text-black'
										/>
									</div>
								) : (
									<div
										className={`w-full h-14 border-[1px] border-gray-200 hover:border-second_color focus:border-second_color rounded-lg flex justify-start items-center gap-2 bg-white  px-5`}>
										<input
											type='text'
											dir='ltr'
											name='sub_address.en'
											{...register("sub_address.en")}
											placeholder='أدخل عنوان الفرعي'
											className='w-full h-full bg-transparent outline-none placeholder:text-gray-300 text-[15px] font-normal text-black'
										/>
										<FaMapMarkerAlt className=' text-[20px] text-second_color opacity-60' />
									</div>
								)}

								{errors?.sub_address && errors?.sub_address.message ? (
									<div
										style={{
											width: "100%",
											color: "red",
											fontSize: "15px",
											marginTop: "5px",
										}}>
										{errors?.sub_address.message}
									</div>
								) : null}
							</section>
						</section>

						{/* Contact via phone number */}
						<h4 className='flex justify-start items-center text-main_color gap-1 mb-2 text-lg font-medium '>
							<FaSquarePhoneFlip className='w-5  text-main_color' />
							التواصل عبر الجوال
						</h4>
						<section className='mb-12 border-[1px] border-slate-100 shadow-lg rounded-md p-8 '>
							<section className='mb-8'>
								<h4 className='mb-1 text-md font-normal'>رقم الجوال</h4>
								<div
									className={`w-full h-14 border-[1px] border-gray-200 hover:border-second_color focus:border-second_color rounded-lg flex justify-start items-center gap-2 bg-white  px-5`}>
									<FaSquarePhoneFlip className=' text-[20px] text-second_color opacity-60' />
									<input
										type='text'
										name='phone'
										maxLength={14}
										{...register("phone")}
										placeholder='أدخل رقم الجوال '
										className='w-full h-full bg-transparent outline-none placeholder:text-gray-300 text-[15px] font-normal text-black'
									/>
								</div>

								{errors?.phone && errors?.phone.message ? (
									<div
										style={{
											width: "100%",
											color: "red",
											fontSize: "15px",
											marginTop: "5px",
										}}>
										{errors?.phone.message}
									</div>
								) : null}
							</section>
						</section>

						{/* Contact via Email  */}
						<h4 className='flex justify-start items-center text-main_color gap-1 mb-2 text-lg font-medium '>
							<MdOutlineMarkEmailRead className='w-5  text-main_color' />
							التواصل عبر الايميل
						</h4>

						<section className='mb-12 border-[1px] border-slate-100 shadow-lg rounded-md p-8 '>
							<section className='mb-8'>
								<h4 className='mb-1 text-md font-normal'>الايميل الرسمي </h4>
								<div
									className={`w-full h-14 border-[1px] border-gray-200 hover:border-second_color focus:border-second_color rounded-lg flex justify-start items-center gap-2 bg-white  px-5`}>
									<MdOutlineMarkEmailRead className=' text-[20px] text-second_color opacity-60' />
									<input
										type='email'
										name='email'
										{...register("email")}
										placeholder='أدخل الايميل الرسمي'
										className='w-full h-full bg-transparent outline-none placeholder:text-gray-300 text-[15px] font-normal text-black'
									/>
								</div>

								{errors?.email && errors?.email.message ? (
									<div
										style={{
											width: "100%",
											color: "red",
											fontSize: "15px",
											marginTop: "5px",
										}}>
										{errors?.email.message}
									</div>
								) : null}
							</section>
							<section className='mb-8'>
								<h4 className='mb-1 text-md font-normal'>ايميل المبيعات </h4>
								<div
									className={`w-full h-14 border-[1px] border-gray-200 hover:border-second_color focus:border-second_color rounded-lg flex justify-start items-center gap-2 bg-white  px-5`}>
									<MdOutlineMarkEmailRead className=' text-[20px] text-second_color opacity-60' />
									<input
										type='email'
										name='sales_email'
										{...register("sales_email")}
										placeholder='أدخل ايميل المبيعات '
										className='w-full h-full bg-transparent outline-none placeholder:text-gray-300 text-[15px] font-normal text-black'
									/>
								</div>

								{errors?.sales_email && errors?.sales_email.message ? (
									<div
										style={{
											width: "100%",
											color: "red",
											fontSize: "15px",
											marginTop: "5px",
										}}>
										{errors?.sales_email.message}
									</div>
								) : null}
							</section>
						</section>
						<section>
							<button
								type='submit'
								className='flex justify-center items-center text-lg font-medium text-white px-8 h-14   bg-main_color hover:bg-second_color gap-2 rounded-md shadow-md '>
								<span> حفظ الاعدادات </span>
								<VscSaveAs />
							</button>
						</section>
					</form>
				)}

				{/* Page Content */}
			</section>
		</>
	);
};

export default AddressSetting;
