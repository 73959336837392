import Switch from "@mui/material/Switch";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { ChangeMainPageStatusThunk } from "../../RTK/Thunk/ChangeMainPageStatusThunk";
import { MainPageThunk } from "../../RTK/Thunk/MainPageThunk";

const SwitchStyle = {
	width: "25px",
	height: "14px",
	padding: "0",
	"& .MuiButtonBase-root ": {
		color: "#fff",
		"&.Mui-checked": {
			color: "#fff",
		},

		"&:hover": {
			backgroundColor: "transparent",
		},

		"&.Mui-checked+.MuiSwitch-track": {
			backgroundColor: "#3AE374",
			opacity: "1",
		},
	},
	"& .MuiSwitch-switchBase ": {
		padding: "1px",
		"&.Mui-checked": {
			transform: "translateX(11px)",
		},
	},

	"& .MuiSwitch-thumb": {
		width: "12px",
		height: "12px",
		boxShadow: "none",
	},
};
const SwitchBox = ({ status, MainPageId }) => {
	const [checked, setChecked] = React.useState(false);
	const dispatch = useDispatch();

	const handleChangeSwitch = () => {
		setChecked(!checked); // Toggle the value of 'checked'
		const newStatus = status ? 0 : 1; // Toggle the status value
		dispatch(
			ChangeMainPageStatusThunk({
				MainPageId: MainPageId,
				status: newStatus,
			})
		)
			.unwrap()
			.then(() => {
				dispatch(MainPageThunk());
			})
			.catch((error) => {
				// handle error here
				// toast.error(error, {
				// 	theme: "light",
				// });
			});
	};

	useEffect(() => {
		if (status) {
			setChecked(status);
		}
	}, [status]);

	return (
		<>
			<Switch
				title='تعطيل/تفعيل'
				sx={SwitchStyle}
				checked={checked}
				onChange={handleChangeSwitch}
				inputProps={{ "aria-label": "controlled" }}
			/>
		</>
	);
};

export default SwitchBox;
