import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const ChangeMainPageStatusThunk = createAsyncThunk(
	"MainPage/ChangeMainPageStatusThunk",
	async (arg, ThunkApi) => {
		const { rejectWithValue } = ThunkApi;

		try {
			const res = await axios.patch(
				`api/admin/main_pages/${arg.MainPageId}?status=${arg.status}`
			);

			return res.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.data);
		}
	}
);
