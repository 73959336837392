import { createSlice } from "@reduxjs/toolkit";

import { AllServicesThunk } from "../Thunk/AllServicesThunk";
import { OneServicePageThunk } from "../Thunk/OneServicePageThunk";
import { DeleteServiceThunk } from "../Thunk/DeleteServiceThunk";
import { AllPartnersThunk } from "../Thunk/AllPartnersThunk";
import { OnePartnerPageThunk } from "../Thunk/OnePartnerPageThunk";
import { DeletePartnerThunk } from "../Thunk/DeletePartnerThunk";

const initState = {
	reload: true,
	partnersData: [],
	currentPage: 1,
	lastPage: 1,
	currentPageData: null,
};

const PartnersReducer = createSlice({
	name: "Partners",

	initialState: initState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			// =======AllPagesThunk data===========
			.addCase(AllPartnersThunk.pending, (state, action) => {
				state.reload = true;
			})
			.addCase(AllPartnersThunk.fulfilled, (state, action) => {
				state.reload = false;
				state.partnersData = action.payload?.data;

				state.currentPage = action.payload.meta.current_page;
				state.lastPage = action.payload.meta.last_page;
			})
			.addCase(AllPartnersThunk.rejected, (state, action) => {
				state.reload = false;
			})
			// =======OnePageThunk data===========
			.addCase(OnePartnerPageThunk.pending, (state, action) => {
				state.reload = true;
			})
			.addCase(OnePartnerPageThunk.fulfilled, (state, action) => {
				state.currentPageData = action.payload.data;
			})
			.addCase(OnePartnerPageThunk.rejected, (state, action) => {
				state.reload = false;
			})

			.addCase(DeletePartnerThunk.pending, (state, action) => {})
			.addCase(DeletePartnerThunk.fulfilled, (state, action) => {})
			.addCase(DeletePartnerThunk.rejected, (state, action) => {});
	},
});

export default PartnersReducer.reducer;
