import { createSlice } from "@reduxjs/toolkit";

import { ChangeProfileDataThunk } from "../Thunk/ChangeProfileDataThunk";
import { ProfileDataThunk } from "../Thunk/ProfileDataThunk";

let initState = {
	reload: false,
	profileData: {
		name: "",
		email: "",
		avatar: "",
	},
	error: null,
};

let ProfileDataReducer = createSlice({
	name: "ProfileData",

	initialState: initState,
	reducers: {},
	extraReducers: (builder) => {
		builder

			.addCase(ChangeProfileDataThunk.pending, (state) => {
				state.reload = true;
			})
			.addCase(ChangeProfileDataThunk.fulfilled, (state, action) => {
				state.reload = false;
				state.profileData = action.payload.data;
				localStorage.setItem("avatar", action.payload.data.avatar);
				localStorage.setItem("name", action.payload.data.name);
			})
			.addCase(ChangeProfileDataThunk.rejected, (state, action) => {
				state.reload = false;
				state.error = action.payload;
			})

			.addCase(ProfileDataThunk.pending, (state) => {
				state.reload = true;
			})
			.addCase(ProfileDataThunk.fulfilled, (state, action) => {
				state.reload = false;
				state.profileData = action.payload.data;
				localStorage.setItem("avatar", action.payload.data.avatar);
				localStorage.setItem("name", action.payload.data.name);
			})
			.addCase(ProfileDataThunk.rejected, (state, action) => {
				state.reload = false;
				state.error = action.error.message;
			});
	},
});
export default ProfileDataReducer.reducer;
