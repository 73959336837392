import { createSlice } from "@reduxjs/toolkit";

import { AllContactUsThunk } from "../Thunk/AllContactUsThunk";
import { DeleteContactUsThunk } from "../Thunk/DeleteContactUsThunk";

const initState = {
	reload: true,
	contactUsData: [],
	currentPage: 1,
	lastPage: 1,
	currentPageData: null,
};

const ContactUsReducer = createSlice({
	name: "ContactUs",

	initialState: initState,
	reducers: {
		showContactUs: (state, action) => {
			state.currentPageData = action?.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			// =======AllPagesThunk data===========
			.addCase(AllContactUsThunk.pending, (state, action) => {
				state.reload = true;
			})
			.addCase(AllContactUsThunk.fulfilled, (state, action) => {
				state.reload = false;
				state.contactUsData = action.payload?.data;

				state.currentPage = action.payload.meta.current_page;
				state.lastPage = action.payload.meta.last_page;
			})
			.addCase(AllContactUsThunk.rejected, (state, action) => {
				state.reload = false;
			})

			.addCase(DeleteContactUsThunk.pending, (state, action) => {})
			.addCase(DeleteContactUsThunk.fulfilled, (state, action) => {})
			.addCase(DeleteContactUsThunk.rejected, (state, action) => {});
	},
});

export const { showContactUs } = ContactUsReducer.actions;
export default ContactUsReducer.reducer;
