import React, { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Helmet } from "react-helmet";

// Icons
import { LuSubtitles } from "react-icons/lu";
import { TbFileDescription } from "react-icons/tb";
import { DummyImage } from "../../../Assets/Images";
import { VscSaveAs } from "react-icons/vsc";

// components
import { LanguagesTaps, PageHeader } from "../../../Components/index";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ShowMainPageThunk } from "../../../RTK/Thunk/ShowMainPageThunk";
import { UpdateMainPageThunk } from "../../../RTK/Thunk/UpdateMainPageThunk";
import { MainPageThunk } from "../../../RTK/Thunk/MainPageThunk";

const EditHomeSectionContent = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { id } = useParams();

	const { tapLanguage } = useSelector((state) => state.LanguageTapsReducer);
	const { currentPage, error } = useSelector((state) => state.MainPageReducer);

	/** get contact data */
	useEffect(() => {
		if (id) {
			dispatch(ShowMainPageThunk({ id: id }));
		}
	}, [id]);

	const [content, setContent] = useState({
		title: {
			ar: "",
			en: "",
		},
		description: {
			ar: "",
			en: "",
		},
	});

	useEffect(() => {
		if (currentPage) {
			setContent({
				...content,
				title: {
					ar: currentPage?.title?.ar,
					en: currentPage?.title?.en,
				},
				description: {
					ar: currentPage?.description?.ar,
					en: currentPage?.description?.en,
				},
			});
		}
	}, [currentPage]);
	// =====validation===========
	const schema = Yup.object().shape({
		title: Yup.object().shape({
			ar: Yup.string().required("العنوان مطلوب"),
			en: Yup.string().required("العنوان مطلوب"),
		}),
		description: Yup.object().shape({
			ar: Yup.string().required(" الوصف مطلوب"),
			en: Yup.string().required(" الوصف مطلوب"),
		}),
	});

	const {
		handleSubmit,
		register,
		reset,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
		mode: "all",
		defaultValues: {
			title: {
				ar: "",
				en: "",
			},
			description: {
				ar: "",
				en: "",
			},
		},
	});

	useEffect(() => {
		reset(content);
	}, [content, reset]);

	/** handle submit  */
	const onSubmit = async (data) => {
		try {
			await dispatch(UpdateMainPageThunk({ ...data, id: id }))
				.unwrap()
				.then((data) => {
					navigate("/");
				});
			dispatch(MainPageThunk());
		} catch (error) {
			// Handle errors here
			console.error("Error updating profile data:", error);
		}
	};
	return (
		<>
			<Helmet>
				<title>لوحة تحكم راج </title>
			</Helmet>
			<section className=' border-[1px] border-slate-100 shadow-lg rounded-md p-8 '>
				<section className='mb-12'>
					<PageHeader
						currentPage={`تعديل محتوي ${
							tapLanguage === "arabic" ? content?.title?.ar : content?.title?.en
						}`}
						parentPage={null}
						route={"/"}
						description={`بامكانك تعديل العنوان الفرعي والوصف القصير لهذا القسم`}
					/>
				</section>
				<form className='w-full' onSubmit={handleSubmit(onSubmit)}>
					{/* change languages taps */}
					<LanguagesTaps />

					{/* Page title */}
					<section className='mb-8'>
						<h4 className='mb-1 text-md font-normal'>عنوان القسم الرئيسي </h4>

						{tapLanguage === "arabic" ? (
							<div
								className={`w-full h-14 border-[1px] border-gray-200 hover:border-second_color focus:border-second_color rounded-lg flex justify-start items-center gap-2 bg-white  px-5`}>
								<LuSubtitles className=' text-[20px] text-second_color' />
								<input
									type='text'
									name='title.ar'
									{...register("title.ar")}
									placeholder='أدخل عنوان القسم الرئيسي'
									className='w-full h-full bg-transparent outline-none placeholder:text-gray-300 text-[15px] font-normal text-black'
								/>
							</div>
						) : (
							<div
								className={`w-full h-14 border-[1px] border-gray-200 hover:border-second_color focus:border-second_color rounded-lg flex justify-start items-center gap-2 bg-white  px-5`}>
								<input
									type='text'
									name='title.en'
									dir='ltr'
									{...register("title.en")}
									placeholder='أدخل عنوان القسم الرئيسي'
									className='w-full h-full bg-transparent outline-none placeholder:text-gray-300 text-[15px] font-normal text-black'
								/>

								<LuSubtitles className=' text-[20px] text-second_color' />
							</div>
						)}

						{(errors?.title?.ar?.message || errors?.title?.en?.message) && (
							<>
								<div className='text-[14px] text-[red] font-normal'>
									{errors?.title?.ar?.message}
								</div>

								<div className='text-[14px] text-[red] font-normal'>
									{errors?.title?.en?.message}
								</div>
							</>
						)}
					</section>

					{/* Short description */}
					<section className='mb-8'>
						<h4 className='mb-1 text-md font-normal'>وصف قصير للقسم</h4>

						{tapLanguage === "arabic" ? (
							<div
								className={`w-full h-28 border-[1px] border-gray-200 hover:border-second_color focus:border-second_color rounded-lg flex justify-start items-start gap-2 bg-white px-5 py-2`}>
								<TbFileDescription className=' text-[20px] text-second_color' />
								<textarea
									name='description.ar'
									{...register("description.ar")}
									placeholder='أدخل وصف قصير للصفحة'
									className=' w-full h-full bg-transparent outline-none placeholder:text-gray-300 text-[15px] font-normal text-black'
								/>
							</div>
						) : (
							<div
								className={`w-full h-28 border-[1px] border-gray-200 hover:border-second_color focus:border-second_color rounded-lg flex justify-start items-start gap-2 bg-white px-5 py-2`}>
								<textarea
									dir='ltr'
									name='description.en'
									{...register("description.en")}
									placeholder='أدخل وصف قصير للصفحة'
									className=' w-full h-full bg-transparent outline-none placeholder:text-gray-300 text-[15px] font-normal text-black'
								/>

								<TbFileDescription className=' text-[20px] text-second_color' />
							</div>
						)}
						{(errors?.description?.ar?.message ||
							errors?.description?.en?.message) && (
							<>
								<div className='text-[14px] text-[red] font-normal'>
									{errors?.description?.ar?.message}
								</div>

								<div className='text-[14px] text-[red] font-normal'>
									{errors?.description?.en?.message}
								</div>
							</>
						)}
					</section>

					{/* page content */}
					<section>
						<button
							type='submit'
							className='flex justify-center items-center text-lg font-medium text-white w-1/2 h-14  mx-auto bg-main_color hover:bg-second_color gap-2 rounded-md shadow-md '>
							<span> حفظ التعديلات </span>
							<VscSaveAs />
						</button>
					</section>
				</form>
			</section>
		</>
	);
};

export default EditHomeSectionContent;
