import React from "react";

import { FiEdit } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { AllPartnersThunk } from "../../../RTK/Thunk/AllPartnersThunk";
import { DeletePartnerThunk } from "../../../RTK/Thunk/DeletePartnerThunk";
import AlertDialog from "../../../Components/AlertDialog/AlertDialog";
import TablePagination from "../../../Components/TableComponent/tablePagination/TablePagination";
import { MdDelete } from "react-icons/md";
import EmptyData from "../../../Components/EmptyData/EmptyData";
import { Loader } from "../../../Components/loader";

const PartnerBox = () => {
	const location = useLocation();
	const pathName = location.pathname.slice(1);
	const navigate = useNavigate();
	const dispatch = useDispatch(false);

	const [deleteId, setDeleteId] = React.useState(0);
	const [openAlert, setOpenAlert] = React.useState(false);
	const [pageTarget, setPageTarget] = React.useState(1);

	const { partnersData, currentPage, lastPage, reload } = useSelector(
		(state) => state.PartnersReducer
	);

	/** get contact data */
	React.useEffect(() => {
		if (pathName === "partners") {
			dispatch(AllPartnersThunk({ page: pageTarget }));
		}
	}, [pathName, pageTarget]);

	// ------------------------------------------------------
	const handleDeleteContact = (id) => {
		dispatch(
			DeletePartnerThunk({
				id: id,
			})
		)
			.unwrap()
			.then((data) => {
				dispatch(AllPartnersThunk({ page: pageTarget }));
			})
			.catch((error) => {
				// handle error here
				// toast.error(error, {
				// 	theme: "light",
				// });
			});
	};
	return (
		<>
			{reload ? (
				<section className=' h-full flex justify-center items-center pt-40'>
					<Loader />
				</section>
			) : (
				<>
					{" "}
					{partnersData?.length === 0 ? (
						<EmptyData />
					) : (
						<section className='grid md:grid-cols-3 grid-cols-1 gap-6'>
							{partnersData?.map((item, idx) => (
								<section
									key={idx}
									className=' rounded-md shadow-lg border-[1px] border-slate-100 '>
									<div className='py-6 h-40 flex justify-center items-center '>
										<img className='w-40 mx-auto' src={item?.logo} alt='' />
									</div>
									<div className=' bg-section_bg flex justify-center items-center gap-4 py-4 border-t-[1px] border-t-slate-200'>
										<MdDelete
											onClick={() => {
												setOpenAlert(true);
												setDeleteId(item.id);
											}}
											title='حذف'
											className='w-6 h-6'
											style={{ color: "#E62237", cursor: "pointer" }}
										/>
										<FiEdit
											onClick={() => {
												navigate(`/edit-partner/${item.id}`);
											}}
											title='تعديل'
											className='w-5 h-5'
											style={{ color: "#faa726", cursor: "pointer" }}
										/>
									</div>
								</section>
							))}
						</section>
					)}
					{/* table Pagination*/}
					{partnersData?.length !== 0 && (
						<TablePagination
							current_page={currentPage}
							count={lastPage}
							setPageTarget={setPageTarget}
						/>
					)}
				</>
			)}

			<AlertDialog
				open={openAlert}
				setOpen={setOpenAlert}
				handleDelete={handleDeleteContact}
				deleteId={deleteId}
				setDeleteId={setDeleteId}
			/>
		</>
	);
};

export default PartnerBox;
