import { createSlice } from "@reduxjs/toolkit";
import { ChangePasswordThunk } from "../Thunk/ChangePasswordThunk";

let initState = {
	reload: false,
	payload: {},
	error: null,
};

let ChangePasswordReducer = createSlice({
	name: "changePassword",

	initialState: initState,
	reducers: {},
	extraReducers: (builder) => {
		builder

			.addCase(ChangePasswordThunk.pending, (state) => {
				state.reload = true;
			})
			.addCase(ChangePasswordThunk.fulfilled, (state, action) => {
				state.reload = false;
				state.payload = action.payload.data;
			})
			.addCase(ChangePasswordThunk.rejected, (state, action) => {
				state.reload = false;
				state.error = action.payload;
			});
	},
});
export default ChangePasswordReducer.reducer;
