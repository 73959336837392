import React from "react";
import ReactQuill from "react-quill";
import "./TextEditor.css";
import "react-quill/dist/quill.snow.css";

import { useSelector } from "react-redux";

const TextEditor = ({ placeholder, arValue, enValue, onChange }) => {
	const { tapLanguage } = useSelector((state) => state.LanguageTapsReducer);

	const toolbarOptions = [
		[{ header: [1, 2, 3, 4, 5, 6, false] }],
		[{ align: [] }],
		["italic", "underline", "bold"],
		[{ background: [] }, { color: [] }],
		[{ list: "ordered" }, { list: "bullet" }],
		["link", "image"],
	];
	return (
		<ReactQuill
			dir={tapLanguage === "arabic" ? "rtl" : "ltr"}
			theme='snow'
			value={tapLanguage === "arabic" ? arValue : enValue}
			placeholder={placeholder}
			style={{ height: "380px" }}
			className={`${
				tapLanguage === "arabic" ? "align-right" : "align-left"
			} bg-section_bg`}
			height={"200px"}
			onChange={onChange}
			modules={{ toolbar: toolbarOptions }}
		/>
	);
};

export default TextEditor;
