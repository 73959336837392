import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const UpdateAboutUsDataThunk = createAsyncThunk(
	"aboutUs/UpdateAboutUsDataThunk",
	async (arg, ThunkApi) => {
		const { rejectWithValue } = ThunkApi;

		try {
			const formData = new FormData();
			formData.append("title[en]", arg?.title?.en);
			formData.append("title[ar]", arg?.title?.ar);
			formData.append("description[en]", arg?.description?.en);
			formData.append("description[ar]", arg?.description?.ar);

			if (arg?.image instanceof File || arg?.image instanceof Blob) {
				formData.append("image", arg.image);
			}

			const res = await axios.post(`api/admin/about_us`, formData);

			return res.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.data);
		}
	}
);
