import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";
import { GoAlert } from "react-icons/go";

let AlertDialog = ({ open, setOpen, handleDelete, setDeleteId, deleteId }) => {
	const handleClose = () => {
		setOpen(false);
		setDeleteId(0);
	};

	let handleDeleteTarget = () => {
		handleDelete(deleteId);
		handleClose();
	};
	return (
		<div>
			<Dialog open={open} onClose={handleClose}>
				<div className='px-10 pt-10 pb-5 text-center'>
					<GoAlert className=' text-second_color w-14 h-14 mx-auto' />

					<DialogTitle id='alert-dialog-title -mb-2'>
						هل أنت متأكد من حذف هذا العنصر
					</DialogTitle>
					<DialogContent>
						<DialogContentText id='alert-dialog-description'>
							لا يمكن استرجاع العنصر بعد حذفه
						</DialogContentText>
					</DialogContent>
					<DialogActions className=' !flex !justify-center !items-center !gap-[15px] '>
						<Button
							className=' !border-[1px]  !border-second_color border-solid  text-second_color text-lg font-normal !py-2 !px-10'
							onClick={handleClose}>
							إلغاء
						</Button>
						<Button
							className=' !bg-[red] !text-white text-lg font-normal  !py-2 !px-10'
							onClick={handleDeleteTarget}
							autoFocus>
							موافق
						</Button>
					</DialogActions>
				</div>
			</Dialog>
		</div>
	);
};
export default React.memo(AlertDialog);
