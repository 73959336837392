import React, { Fragment, useEffect, useState } from "react";
import ReactDom from "react-dom";

// icon
import { IoCloseSharp } from "react-icons/io5";

// css styles
import styles from "./MessageDetails.module.css";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const MessageDetails = () => {
	const navigate = useNavigate();

	const { currentPageData } = useSelector((state) => state.ContactUsReducer);

	// handle service Info Values
	const [customerServiceInfoValues, setCustomerServiceInfoValues] = useState({
		name: "",
		phone: "",
		email: "",
		title: "",
		message: "",
	});

	useEffect(() => {
		if (currentPageData) {
			setCustomerServiceInfoValues({
				...customerServiceInfoValues,
				name: currentPageData?.name,
				phone: currentPageData?.phone,
				email: currentPageData?.email,
				title: currentPageData?.title,
				message: currentPageData?.message,
			});
		}
	}, [currentPageData]);

	// handle in change
	const handleOnChangeCustomerServiceInfo = (e) => {
		const { name, value } = e.target;
		setCustomerServiceInfoValues((prevState) => {
			return { ...prevState, [name]: value };
		});
	};

	return (
		<div>
			{/* Backdrop */}
			<div className={`${styles.backdrop} z-20`} />
			<div
				className={` ${styles.z_index_100} absolute top-20 left-0 translate-x-[40%] md:w-[55%] w-full  bg-white rounded-[10px]`}>
				{/* title of modal */}
				<div
					className={`w-full h-11 bg-semi_second_color  flex justify-center items-center px-6 ${styles.modal_title}`}>
					<h4 className='text-[16px] text-[#200407] font-medium flex-1 text-center'>
						تفاصيل الرسالة
					</h4>

					<div
						className='cursor-pointer '
						onClick={() => navigate("/contact-us")}>
						<IoCloseSharp className='close-video-icon' />
					</div>
				</div>

				{/* content of modal */}
				<div className=' py-5 w-full overflow-auto'>
					{/* full Name */}
					<div className='border-b-[1px] border-solid border-[#e9e9e9] px-5 mb-5'>
						<h4 className='mb-3 text-[#707070] font-normal text-[16px]'>
							اسم العميل
						</h4>
						<input
							readOnly
							name='name'
							type='text'
							value={customerServiceInfoValues?.name}
							onChange={handleOnChangeCustomerServiceInfo}
							placeholder='أحمد عبد العزيز المالكي'
							className='w-full h-7 border-none focus:border-none focus:outline-none font-normal text-[16px] text-black'
						/>
					</div>

					{/* Email */}
					<div className='border-b-[1px] border-solid border-[#e9e9e9] px-5 mb-5'>
						<h4 className='mb-3 text-[#707070] font-normal text-[16px]'>
							البريد الالكتروني
						</h4>
						<input
							readOnly
							name='name'
							type='email'
							value={customerServiceInfoValues?.email}
							onChange={handleOnChangeCustomerServiceInfo}
							placeholder='Ahmed@gmail.com'
							className='w-full h-7 border-none focus:border-none focus:outline-none font-normal text-[16px] text-black'
						/>
					</div>

					{/* phone */}
					<div className='border-b-[1px] border-solid border-[#e9e9e9] px-5 mb-5'>
						<h4 className='mb-3 text-[#707070] font-normal text-[16px]'>
							رقم الجوال
						</h4>
						<input
							readOnly
							name='phone'
							type='tel'
							value={customerServiceInfoValues?.phone}
							onChange={handleOnChangeCustomerServiceInfo}
							placeholder='96651545461'
							className='w-full h-7 border-none focus:border-none focus:outline-none font-normal text-[16px] text-black'
						/>
					</div>

					{/* Email */}
					<div className='border-b-[1px] border-solid border-[#e9e9e9] px-5 mb-5'>
						<h4 className='mb-3 text-[#707070] font-normal text-[16px]'>
							عنوان الرسالة
						</h4>
						<input
							readOnly
							name='name'
							type='title'
							value={customerServiceInfoValues?.title}
							onChange={handleOnChangeCustomerServiceInfo}
							placeholder='استفسار حول'
							className='w-full h-7 border-none focus:border-none focus:outline-none font-normal text-[16px] text-black'
						/>
					</div>

					{/* message */}
					<div className='  px-5 mb-5'>
						<h4 className='mb-3 text-[#707070] font-normal text-[16px]'>
							محتوي الرسالة
						</h4>
						<textarea
							readOnly
							name='message'
							value={customerServiceInfoValues?.message}
							onChange={handleOnChangeCustomerServiceInfo}
							placeholder='أرغب بالاستفسار بشكل مفصل عن برنامج الرحلة التجارية إلى الصين'
							className='w-full h-56 p-5 bg-[#f2f2f2] resize-none font-normal text-[16px] text-black  '
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

const MessageDetailsModal = () => {
	return (
		<Fragment>
			{ReactDom.createPortal(
				<MessageDetails />,
				document.getElementById("message-details")
			)}
		</Fragment>
	);
};

export default MessageDetailsModal;
