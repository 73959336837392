import React, { useEffect } from "react";

// React router dom
import { useNavigate } from "react-router-dom";

// import css styles
import "../../index.css";
import styles from "./LoginPage.module.css";

// import icons and images
import { SlEye } from "react-icons/sl";
import { FaEyeSlash } from "react-icons/fa";
import { MainLogo } from "../../Assets/Images";
import { Email, VpnKey } from "@mui/icons-material";

import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// Redux
import { useDispatch } from "react-redux";
import { LoginThunk } from "../../RTK/Thunk/LoginThunk";

// =====validation===========
const schema = Yup.object().shape({
	email: Yup.string()
		.email("عنوان البريد الإلكتروني غير صالح")
		.required("مطلوب"),
	pass: Yup.string().required("مطلوب"),
});

const LoginPage = () => {
	let dispatch = useDispatch();
	const navigate = useNavigate();

	// to handle show and hide password
	const [showPassword, setShowPassword] = React.useState(false);
	const [showErrorForm, setShowErrorForm] = React.useState({
		type: false,
		data: "",
	});

	// handel path and error form
	useEffect(() => {
		if (localStorage.getItem("token")) {
			navigate("/");
		}
	}, [navigate]);

	const {
		handleSubmit,
		register,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
		mode: "all",
		defaultValues: {
			email: "",
			pass: "",
		},
	});
	const onSubmit = async (data) => {
		dispatch(LoginThunk(data))
			.unwrap()
			.then((data) => {
				navigate("/");
			})
			.catch((error) => {
				if (error?.code !== null) {
					setShowErrorForm({ type: true, data: error?.message });
				}
			});
	};
	return (
		<div className={`${styles.login_bg} flex justify-center items-center`}>
			<div className='text-center flex  flex-col bg-white  justify-center items-center w-[520px] p-8 m-auto shadow-xl border-[1px] border-slate-200 rounded-xl'>
				<div className='mx-auto  flex  justify-center items-center mb-8 cursor-pointer'>
					<img
						className='w-60 h-auto'
						src={MainLogo}
						alt=''
						loading='lazy'
						onClick={() => window.open("https://raj.com.sa/")}
					/>
				</div>
				<form className='w-full' onSubmit={handleSubmit(onSubmit)}>
					{/* email */}
					<div className='w-full border-[1px] border-gray-100 hover:border-second_color focus:border-second_color h-14 rounded-[28px] flex justify-start items-center gap-2 bg-white mb-4 px-5'>
						<Email className=' text-[20px] text-second_color' />
						<input
							type='email'
							name='email'
							{...register("email")}
							placeholder='قم بإدخال البريد الالكتروني'
							className=' w-4/5 h-full bg-transparent outline-none  placeholder:text-gray-300 text-[15px] font-normal text-black'
						/>
						{errors?.email && errors?.email.message ? (
							<span
								style={{
									width: "100%",
									color: "red",
									fontSize: "15px",
									marginTop: "5px",
								}}>
								{errors?.email.message}
							</span>
						) : null}
					</div>

					{/* password */}
					<div
						className={`w-full h-14 border-[1px] border-gray-100 hover:border-second_color focus:border-second_color rounded-[28px] flex justify-start items-center gap-2 bg-white ${"mb-6"} px-5`}>
						<VpnKey className=' text-[20px] text-second_color' />
						<input
							type={showPassword ? "text" : "password"}
							{...register("pass")}
							placeholder='••••••••'
							className='w-4/5 h-full bg-transparent outline-none placeholder:text-gray-300 text-[15px] font-normal text-black'
						/>
						{errors?.pass && errors?.pass?.message ? (
							<span
								style={{
									width: "100%",
									color: "red",
									fontSize: "15px",
									marginTop: "5px",
								}}>
								{errors?.pass?.message}
							</span>
						) : null}
						<div className=' w-[25px] h-[25px] flex justify-center items-center'>
							{showPassword ? (
								<SlEye
									className='cursor-pointer w-6 h-6 open-eye'
									onClick={() => setShowPassword(!showPassword)}
								/>
							) : (
								<FaEyeSlash
									className='cursor-pointer'
									onClick={() => setShowPassword(!showPassword)}
								/>
							)}
						</div>
					</div>
					<div className='w-full flex justify-center '>
						<button
							type='submit'
							className={`w-full h-[53px]  rounded-full bg-main_color hover:bg-second_color transition-[0.3s]  text-white text-xl font-normal`}>
							تسجيل الدخول
						</button>
					</div>
					<span
						style={{
							display: showErrorForm?.type === true ? "block" : "none",
							color: "red",
							textAlign: "center",
							width: "100%",
							marginTop: "10px",
						}}>
						{showErrorForm?.data}
					</span>
				</form>
			</div>
		</div>
	);
};

export default LoginPage;
