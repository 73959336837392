import axios from "axios";
import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const HTTP_UNAUTHORIZED = 401,
	HTTP_FORBIDDEN = 403,
	HTTP_NOT_FOUND = 404;

const AxiosInterceptors = ({ children }) => {
	let navigate = useNavigate();
	// ======axios_interceptors===============
	useEffect(() => {
		axios.interceptors.response.use(null, (error) => {
			if (!error?.request?.responseURL.includes("login")) {
				if (JSON.parse(error?.response?.request?.response)?.message) {
					toast.error(JSON.parse(error?.response?.request?.response)?.message, {
						theme: "light",
					});
				}

				if (error.response.status === HTTP_UNAUTHORIZED) {
					// console.log("error.response.status");
					localStorage.clear();
					navigate("/login");
				}
				// else if (error.response.status == HTTP_NOT_FOUND) {
				//   navigate("page404");
				// }
				else if (error.response.status === HTTP_FORBIDDEN) {
					localStorage.clear();
					navigate("/login");
				}
			}

			return Promise.reject(error);
		});
	}, [navigate]);
	return <>{children}</>;
};

export default AxiosInterceptors;
