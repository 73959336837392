import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export let OneProjectPageThunk = createAsyncThunk(
	"Projects/OneProjectPageThunk",
	async (arg, ThunkApi) => {
		let { rejectWithValue } = ThunkApi;
		try {
			let res = await axios.get(`/api/admin/projects/${arg?.id}`);

			return res.data;
		} catch (error) {
			return rejectWithValue(error.response.data.data);
		}
	}
);
