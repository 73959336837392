import React from "react";
import {
	PageHeader,
	SectorSolutionsTable,
	TableComponent,
} from "../../../Components";
import AddNewSector from "./AddNewSector";

import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import EditSectorSolution from "./EditSectorSolution";

const SectorSolutions = () => {
	const location = useLocation();
	const pathName = location.pathname.slice(1);

	return (
		<>
			<Helmet>
				<title>لوحة تحكم راج | حلول القطاعات</title>
			</Helmet>
			<section className=''>
				<PageHeader
					addBtn={"اضافه حلول جديدة"}
					navigateTo={"/create-new-sector"}
					parentPage={null}
					description={
						"بامكانك اضافه وصف للصفحه بالاضافه الي اضافه العديد  من حلول القطاعات"
					}
				/>
				{/*Sector solution table*/}
				<section className=''>
					<h4 className=' text-md font-normal'>حلول القطاعات الحالية</h4>

					<SectorSolutionsTable />
				</section>
				{/* Page Content */}
				{pathName === "create-new-sector" && <AddNewSector />}
				{pathName === "edit-sector" && <EditSectorSolution />}
			</section>
		</>
	);
};

export default SectorSolutions;
