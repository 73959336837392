import React from "react";
import { PageHeader, ServicesTable } from "../../../Components";

import { AddNewService, EditService } from "..";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

const Services = () => {
	const location = useLocation();
	const pathName = location.pathname.slice(1);

	return (
		<>
			<Helmet>
				<title>لوحة تحكم راج | خدماتنا</title>
			</Helmet>

			<section className=''>
				<PageHeader
					addBtn={"اضافه خدمة جديدة"}
					navigateTo={"/add-new-service"}
					currentPage={"خدماتنا"}
					parentPage={null}
					description={
						"بامكانك اضافه وصف للصفحه بالاضافه الي اضافه العديد  من الخدمات "
					}
				/>

				{/*Sector solution table*/}
				<section className=''>
					<h4 className=' text-md font-normal'> الخدمات الحالية</h4>

					<ServicesTable />
				</section>

				{/* Page Content */}

				{pathName === "add-new-service" && <AddNewService />}
				{pathName === "edit-service" && <EditService />}
			</section>
		</>
	);
};

export default Services;
