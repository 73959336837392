import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const ChangeProfileDataThunk = createAsyncThunk(
	"myAccount/ChangeProfileDataThunk",
	async (arg, ThunkApi) => {
		const { rejectWithValue } = ThunkApi;

		try {
			const formData = new FormData();
			formData.append("name", arg?.name);
			formData.append("email", arg?.email);

			if (arg?.avatar instanceof File || arg?.avatar instanceof Blob) {
				formData.append("avatar", arg.avatar);
			}

			const res = await axios.post(`auth/profile`, formData);

			return res.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.data);
		}
	}
);
