import React, { useEffect, useState } from "react";

import { Helmet } from "react-helmet";

// Icons
import { IoMdPhotos } from "react-icons/io";
import { FaCloudUploadAlt } from "react-icons/fa";
import { DummyImage } from "../../../Assets/Images";
import { VscSaveAs } from "react-icons/vsc";

// components
import { PageHeader } from "../../../Components/index";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AddNewPartnerThunk } from "../../../RTK/Thunk/AddNewPartnerThunk";
import { OnePartnerPageThunk } from "../../../RTK/Thunk/OnePartnerPageThunk";

const EditPartner = () => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { currentPageData, error } = useSelector(
		(state) => state.PartnersReducer
	);

	const [imagePreview, setImagePreview] = useState(null);
	const [content, setContent] = useState({
		logo: DummyImage,
	});

	useEffect(() => {
		if (currentPageData) {
			setContent({
				...content,

				logo: currentPageData?.logo,
			});
		}
	}, [currentPageData]);

	/** get contact data */
	useEffect(() => {
		if (id) {
			dispatch(OnePartnerPageThunk({ id: id }));
		}
	}, [id]);

	// upload images
	const fileInputRef = React.createRef();
	const handleButtonClick = (e) => {
		e.preventDefault();
		fileInputRef.current.click();
	};
	const handleUploadImages = (event) => {
		const file = event.target.files[0];
		setContent({ ...content, logo: file });

		// Create a temporary URL for the selected file and set it as the imagePreview state
		const imageUrl = URL.createObjectURL(file);
		setImagePreview(imageUrl);
	};

	/** handle submit  */
	const handleEditPartner = async () => {
		try {
			await dispatch(
				AddNewPartnerThunk({
					logo: content?.logo,
				})
			)
				.unwrap()
				.then((data) => {
					navigate("/partners");
				});
		} catch (error) {
			// Handle errors here
			console.error("Error updating profile data:", error);
		}
	};

	return (
		<>
			<Helmet>
				<title>لوحة تحكم راج | تعديل شريك </title>
			</Helmet>
			<section className=' border-[1px] border-slate-100 shadow-lg rounded-md p-8 '>
				<section className='mb-12'>
					<PageHeader
						currentPage={"تعديل شريك "}
						parentPage={"شركائنا"}
						route={"/partners"}
						description={
							"قم اضافه المحتوي الخاص بالشركاء  لقائمه الشركاء الحاليه"
						}
					/>
				</section>
				<div className='w-full'>
					<section className='mb-8'>
						<h4 className='flex justify-start items-center gap-1 mb-1 text-md font-normal'>
							<IoMdPhotos className='w-6 h-6 text-main_color' />
							صورة الصفحة
						</h4>

						<section className='w-full '>
							{/* IMAGE PREVIEW */}
							<div className=' w-[50%] h-[220px] mx-auto border-[1px] border-[#ddd] rounded-md p-1 mb-4 '>
								<div className='p-1 w-full h-full flex justify-center items-center'>
									{imagePreview ? (
										<img
											src={imagePreview}
											alt=''
											className='rounded-md h-full'
										/>
									) : (
										<img
											src={content?.logo}
											alt=''
											className='rounded-md h-full'
										/>
									)}
								</div>
							</div>

							<div className='w-[50%] h-[48px] flex justify-center items-center bg-white border-[2px] border-dashed border-unnamed_color_119184  m-auto mb-1 cursor-pointer'>
								<input
									type='file'
									accept='image/*'
									onChange={handleUploadImages}
									ref={fileInputRef}
									/* hidden this input and use icon below to upload  */
									style={{ display: "none" }}
								/>

								{/* Button to trigger file input */}
								<button
									onClick={handleButtonClick}
									className='w-full h-full flex justify-center items-center'>
									<FaCloudUploadAlt
										className='w-6 h-6'
										title='تحديث صورة الشريك'
									/>
								</button>
							</div>
							{error?.logo && (
								<div className='text-[14px] text-[red] font-normal'>
									{error?.logo}
								</div>
							)}
						</section>
					</section>

					{/* page content */}
					<section>
						<button
							onClick={handleEditPartner}
							className='flex justify-center items-center text-lg font-medium text-white w-1/2 h-14  mx-auto bg-main_color hover:bg-second_color gap-2 rounded-md shadow-md '>
							<span> تعديل الشريك </span>
							<VscSaveAs />
						</button>
					</section>
				</div>
			</section>
		</>
	);
};

export default EditPartner;
