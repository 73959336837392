import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	AppBar,
	Avatar,
	Box,
	IconButton,
	Menu,
	MenuItem,
	Toolbar,
} from "@mui/material";
import { CiSettings } from "react-icons/ci";
import { HiOutlineUser } from "react-icons/hi";
import { IoIosArrowDown } from "react-icons/io";
import { UserImage, WhiteLogo } from "../../Assets/Images";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { LogoutThunk } from "../../RTK/Thunk/LogoutThunk";
import { ProfileDataThunk } from "../../RTK/Thunk/ProfileDataThunk";

const AppBarsStyles = {
	"&.MuiPaper-root": {
		height: "65px",
		boxShadow: "none",
		backgroundColor: "#315ca7",
	},
};

const ToolbarStyles = {
	"&.MuiToolbar-root": {
		paddingRight: "290px",
		paddingLeft: "90px",
		minHeight: "65px",
	},
};

const muiItemStyles = {
	"&.MuiMenuItem-root": {
		height: "48px",
		fontFamily: "Tajawal",
		fontSize: "16px",
		fontWeight: 500,
		color: "#f6f6f6",
		"&:hover": {
			backgroundColor: "#315ca7",
			color: "#fff",
		},
	},
};

const StyledBadge = styled(Avatar)(({ theme }) => ({
	"& .MuiBadge-badge": {
		backgroundColor: "#44b700",
		color: "#44b700",
		boxShadow: `0 0 0 1px ${theme.palette.background.paper}`,
		"&::after": {
			position: "absolute",
			top: 0,
			left: 0,
			width: "100%",
			height: "100%",
			borderRadius: "50%",
			animation: "ripple 1.5s infinite ease-in-out",
			border: "1px solid currentColor",
			content: '""',
		},
	},
	"@keyframes ripple": {
		"0%": {
			transform: "scale(.8)",
			opacity: 1,
		},
		"100%": {
			transform: "scale(2.4)",
			opacity: 0,
		},
	},
}));

const TopBar = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = useState(null);

	const { profileData } = useSelector((state) => state.ProfileDataReducer);

	const isMenuOpen = Boolean(anchorEl);
	const handleProfileMenuOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const logout = () => {
		dispatch(LogoutThunk())
			.unwrap()
			.then((data) => {
				localStorage.clear();
				navigate("/login");
			});
	};

	const menuId = "primary-search-account-menu";
	const renderMenu = (
		<Menu
			sx={{
				"& .MuiPaper-root ": {
					width: "180px",
					backgroundColor: "#315ca7",
					top: "65px !important",
					boxShadow: "0px 0px 1px 0px #faa726",
				},
				"& .MuiList-padding": {
					paddingTop: "0",
					paddingBottom: "0",
				},
			}}
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
			id={menuId}
			keepMounted
			transformOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
			open={isMenuOpen}
			onClose={handleMenuClose}>
			<MenuItem
				className='group hover:bg-second_color'
				onClick={() => {
					handleMenuClose();
					navigate("/edit-my-account");
				}}
				sx={muiItemStyles}>
				<HiOutlineUser className={` w-6 h-6 group-hover:text-white  ml-2`} />
				تعديل حسابي
			</MenuItem>
			<MenuItem
				className='group hover:bg-second_color'
				onClick={() => {
					logout();
					handleMenuClose();
				}}
				sx={muiItemStyles}>
				<CiSettings className={` w-6 h-6 group-hover:text-white ml-2`} />
				تسجيل الخروج
			</MenuItem>
		</Menu>
	);

	return (
		<Box sx={{ flexGrow: 1 }}>
			<AppBar position='fixed' sx={AppBarsStyles}>
				<Box className='app_container'>
					<Toolbar sx={ToolbarStyles}>
						<Box sx={{ display: "flex", gap: "22px" }}>
							<IconButton
								sx={{
									display: "flex",
									gap: "10px",
									"&.MuiIconButton-root": {
										"&:hover": { backgroundColor: "transparent" },
									},
								}}
								size='large'
								edge='end'
								aria-label='account of current user'
								aria-controls={menuId}
								aria-haspopup='true'
								onClick={handleProfileMenuOpen}
								color='inherit'>
								<StyledBadge
									overlap='circular'
									anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
									variant='dot'>
									<Avatar
										alt='user name'
										src={
											profileData?.avatar
												? profileData?.avatar
												: localStorage.getItem("avatar")
										}
									/>
								</StyledBadge>

								<Box className='flex justify-center items-center gap-1 '>
									{profileData?.name ? (
										<p className='text-[16px] text-white font-light font-Tajawal'>
											{profileData?.name}
										</p>
									) : (
										<p className='text-[16px] text-white font-light font-Tajawal'>
											{localStorage.getItem("name")}
										</p>
									)}
									<IoIosArrowDown className=' text-xl' />
								</Box>
							</IconButton>
						</Box>

						<Box sx={{ flexGrow: 1 }} />

						<div>
							<img
								className='w-24 h-auto'
								src={localStorage.getItem("footerLogo") || WhiteLogo}
								alt=''
							/>
						</div>
					</Toolbar>
				</Box>
			</AppBar>

			{renderMenu}
		</Box>
	);
};

export default TopBar;
