import React from "react";
import { Outlet, ScrollRestoration } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { PrivateRoute } from "./login/PrivateRoute";
import { SideBar, TopBar } from "../Components";

const RootLayout = () => {
	return (
		<PrivateRoute>
			<main>
				<TopBar />

				{/* This is Toast Library to Handle errors modal in dashboard*/}
				<ToastContainer
					rtl
					draggable
					closeOnClick
					autoClose={2000}
					pauseOnFocusLoss
					position='top-center'
					newestOnTop={false}
					hideProgressBar={false}
					style={{ fontSize: "14px", color: "#000", whiteSpace: "normal" }}
				/>

				<section className='flex justify-start pt-16'>
					<section className='w-[300px]'>
						<SideBar />
					</section>

					{/* the content of dashboard */}
					<section className='w-5/6 py-10 px-12'>
						{/** use ScrollRestoration from react router dom to fix scrolling issue */}
						<ScrollRestoration />
						<Outlet />
					</section>
				</section>
			</main>
		</PrivateRoute>
	);
};

export default RootLayout;
