import { createSlice } from "@reduxjs/toolkit";

import { AllServicesThunk } from "../Thunk/AllServicesThunk";
import { OneServicePageThunk } from "../Thunk/OneServicePageThunk";
import { DeleteServiceThunk } from "../Thunk/DeleteServiceThunk";

const initState = {
	reload: true,
	servicesData: [],
	currentPage: 1,
	lastPage: 1,
	currentPageData: null,
};

const ServicesReducer = createSlice({
	name: "Services",

	initialState: initState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			// =======AllPagesThunk data===========
			.addCase(AllServicesThunk.pending, (state, action) => {
				state.reload = true;
			})
			.addCase(AllServicesThunk.fulfilled, (state, action) => {
				state.reload = false;
				state.servicesData = action.payload?.data;

				state.currentPage = action.payload.meta.current_page;
				state.lastPage = action.payload.meta.last_page;
			})
			.addCase(AllServicesThunk.rejected, (state, action) => {
				state.reload = false;
			})
			// =======OnePageThunk data===========
			.addCase(OneServicePageThunk.pending, (state, action) => {
				state.reload = true;
			})
			.addCase(OneServicePageThunk.fulfilled, (state, action) => {
				state.currentPageData = action.payload.data;
			})
			.addCase(OneServicePageThunk.rejected, (state, action) => {
				state.reload = false;
			})

			.addCase(DeleteServiceThunk.pending, (state, action) => {})
			.addCase(DeleteServiceThunk.fulfilled, (state, action) => {})
			.addCase(DeleteServiceThunk.rejected, (state, action) => {});
	},
});

export default ServicesReducer.reducer;
