import React, { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// Icons
import { LuSubtitles } from "react-icons/lu";
import { FaCloudUploadAlt } from "react-icons/fa";
import { DummyImage } from "../../../Assets/Images";
import { VscSaveAs } from "react-icons/vsc";

// components
import {
	PageHeader,
	TextEditor,
	LanguagesTaps,
} from "../../../Components/index";
import { Helmet } from "react-helmet";
import { IoMdPhotos } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { TextEditorContext } from "../../../Context/TextEditorProvider";

import { useNavigate, useParams } from "react-router-dom";
import { EditServiceThunk } from "../../../RTK/Thunk/EditServiceThunk";
import { OneServicePageThunk } from "../../../RTK/Thunk/OneServicePageThunk";

const EditService = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { id } = useParams();

	const { tapLanguage } = useSelector((state) => state.LanguageTapsReducer);
	const { currentPageData, error } = useSelector(
		(state) => state.ServicesReducer
	);
	const [imagePreview, setImagePreview] = useState(null);

	/** get contact data */
	useEffect(() => {
		if (id) {
			dispatch(OneServicePageThunk({ id: id }));
		}
	}, [id]);

	// To get the editor content
	const editorContent = useContext(TextEditorContext);
	const {
		editorValueAr,
		setEditorValueAr,

		editorValueEn,
		setEditorValueEn,
	} = editorContent;

	const [content, setContent] = useState({
		title: {
			ar: "",
			en: "",
		},
		image: DummyImage,
	});

	useEffect(() => {
		if (currentPageData) {
			setContent({
				...content,
				title: {
					ar: currentPageData?.title?.ar,
					en: currentPageData?.title?.en,
				},
				image: currentPageData?.image,
			});

			setEditorValueAr(currentPageData?.description?.ar);
			setEditorValueEn(currentPageData?.description?.en);
		}
	}, [currentPageData]);

	// =====validation===========
	const schema = Yup.object().shape({
		title: Yup.object().shape({
			ar: Yup.string().required("العنوان مطلوب"),
			en: Yup.string().required("Title is required"),
		}),
	});

	const {
		handleSubmit,
		register,
		reset,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
		mode: "all",
		defaultValues: {
			title: {
				ar: "",
				en: "",
			},
		},
	});

	useEffect(() => {
		reset(content);
	}, [content, reset]);

	// upload images
	const fileInputRef = React.createRef();
	const handleButtonClick = (e) => {
		e.preventDefault();
		fileInputRef.current.click();
	};
	const handleUploadImages = (event) => {
		const file = event.target.files[0];
		setContent({ ...content, image: file });

		// Create a temporary URL for the selected file and set it as the imagePreview state
		const imageUrl = URL.createObjectURL(file);
		setImagePreview(imageUrl);
	};

	/** handle submit  */
	const onSubmit = async (data) => {
		try {
			await dispatch(
				EditServiceThunk({
					...data,
					image: content?.image,
					description: { ar: editorValueAr, en: editorValueEn },
					id: id,
				})
			)
				.unwrap()
				.then((data) => {
					navigate("/services");
				});
		} catch (error) {
			// Handle errors here
			console.error("Error updating profile data:", error);
		}
	};
	return (
		<>
			<Helmet>
				<title>لوحة تحكم راج | تعديل خدمة </title>
			</Helmet>
			<section className=' border-[1px] border-slate-100 shadow-lg rounded-md p-8 '>
				<section className='mb-12'>
					<PageHeader
						currentPage={"تعديل خدمه "}
						parentPage={" خدماتنا"}
						route={"/services"}
						description={
							"بامكانك  تعديل المحتوي الخاص بالخدمة  التي قمت باضافتها لقائمه الخدمات الحاليه"
						}
					/>
				</section>
				<form className='w-full' onSubmit={handleSubmit(onSubmit)}>
					{/* change languages taps */}
					<LanguagesTaps />

					{/* Page title */}
					<section className='mb-8'>
						<h4 className='mb-1 text-md font-normal'>عنوان الصفحة</h4>

						{tapLanguage === "arabic" ? (
							<div
								className={`w-full h-14 border-[1px] border-gray-200 hover:border-second_color focus:border-second_color rounded-lg flex justify-start items-center gap-2 bg-white  px-5`}>
								<LuSubtitles className=' text-[20px] text-second_color' />
								<input
									type='text'
									name='title.en'
									{...register("title.ar")}
									placeholder='أدخل عنوان الصفحة'
									className='w-full h-full bg-transparent outline-none placeholder:text-gray-300 text-[15px] font-normal text-black'
								/>
							</div>
						) : (
							<div
								className={`w-full h-14 border-[1px] border-gray-200 hover:border-second_color focus:border-second_color rounded-lg flex justify-start items-center gap-2 bg-white  px-5`}>
								<input
									type='text'
									dir='ltr'
									name='title.en'
									{...register("title.en")}
									placeholder='أدخل عنوان الصفحة'
									className='w-full h-full bg-transparent outline-none placeholder:text-gray-300 text-[15px] font-normal text-black'
								/>
								<LuSubtitles className=' text-[20px] text-second_color' />
							</div>
						)}

						{(errors?.title?.ar?.message || errors?.title?.en?.message) && (
							<>
								<div className='text-[14px] text-[red] font-normal'>
									{errors?.title?.ar?.message}
								</div>

								<div className='text-[14px] text-[red] font-normal'>
									{errors?.title?.en?.message}
								</div>
							</>
						)}
					</section>

					{/* upload image*/}
					<section className='mb-8'>
						<h4 className='flex justify-start items-center gap-1 mb-1 text-md font-normal'>
							<IoMdPhotos className='w-6 h-6 text-main_color' />
							صورة الصفحة
						</h4>

						<section className='w-full '>
							{/* IMAGE PREVIEW */}
							<div className=' w-[50%] h-[220px] mx-auto border-[1px] border-[#ddd] rounded-md p-1 mb-4 '>
								<div className='p-1 w-full h-full flex justify-center items-center'>
									{imagePreview ? (
										<img
											src={imagePreview}
											alt=''
											className='rounded-md h-full'
										/>
									) : (
										<img
											src={content?.image}
											alt=''
											className='rounded-md  h-full'
										/>
									)}
								</div>
							</div>

							<div className='w-[50%] h-[48px] flex justify-center items-center bg-white border-[2px] border-dashed border-unnamed_color_119184  m-auto mb-1 cursor-pointer'>
								<input
									type='file'
									accept='image/*'
									onChange={handleUploadImages}
									ref={fileInputRef}
									/* hidden this input and use icon below to upload  */
									style={{ display: "none" }}
								/>

								{/* Button to trigger file input */}

								<button
									onClick={handleButtonClick}
									className='w-full h-full flex justify-center items-center'>
									<FaCloudUploadAlt
										className='w-6 h-6'
										title='تحديث صورة المستخدم'
									/>
								</button>
							</div>

							{error?.image && (
								<div className='text-[14px] text-[red] font-normal'>
									{error?.image}
								</div>
							)}
						</section>
					</section>

					{/* page content */}
					<section className='mb-12'>
						<h4 className=' text-md font-normal mb-2'>محتوي الصفحة</h4>

						{tapLanguage === "arabic" ? (
							<TextEditor
								placeholder='المحتوي الخاص بالصفحه من صور ونصوص'
								arValue={editorValueAr}
								onChange={setEditorValueAr}
							/>
						) : (
							<TextEditor
								placeholder='المحتوي الخاص بالصفحه من صور ونصوص'
								enValue={editorValueEn}
								onChange={setEditorValueEn}
							/>
						)}
					</section>

					{/* page content */}
					<section>
						<button
							type='submit'
							className='flex justify-center items-center text-lg font-medium text-white w-1/2 h-14  mx-auto bg-main_color hover:bg-second_color gap-2 rounded-md shadow-md '>
							<span> حفظ الخدمة </span>
							<VscSaveAs />
						</button>
					</section>
				</form>
			</section>
		</>
	);
};

export default EditService;
