import React from "react";
import { PageHeader, ProjectsTable } from "../../../Components";

import { AddNewProjects, EditProject } from "..";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

const Projects = () => {
	const location = useLocation();
	const pathName = location.pathname.slice(1);
	return (
		<>
			<Helmet>
				<title>لوحة تحكم راج | مشاريعنا</title>
			</Helmet>
			<section className=''>
				<PageHeader
					addBtn={"اضافه مشروع جديد"}
					navigateTo={"/add-new-project"}
					currentPage={"مشاريعنا"}
					parentPage={null}
					description={
						"بامكانك اضافه وصف للصفحه بالاضافه الي اضافه العديد من المشاريع "
					}
				/>

				{/* Sector solution table*/}
				<section className=''>
					<h4 className='text-lg font-medium'> المشاريع الحالية</h4>

					<ProjectsTable />
				</section>

				{/* Page Content */}

				{pathName === "add-new-project" && <AddNewProjects />}
				{pathName === "edit-project" && <EditProject />}
			</section>
		</>
	);
};

export default Projects;
