import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export default function PageNotFound() {
	return (
		<>
			<Helmet>
				<title>لوحة تحكم راج | صفحة غير موجودة</title>
			</Helmet>
			<div className={`  bg-section_bg text-lg`}>
				<div className='h-screen flex justify-center items-center flex-col'>
					<h1 className=' text-second_color text-4xl font-medium mb-5'>خطأ!</h1>
					<p className=' text-main_color text-xl font-normal'>
						حدث خطأ ما , يرجى المحاولة لاحقاً
					</p>
					<section>
						<Link
							className=' text-slate-600 underline  font-normal text-xl'
							to='/'>
							الصفحة الرئيسية
						</Link>
					</section>
				</div>
			</div>
		</>
	);
}
