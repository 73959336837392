import { createSlice } from "@reduxjs/toolkit";

import { UpdateSiteLogoThunk } from "../Thunk/UpdateSiteLogoThunk";
import { SiteLogoThunk } from "../Thunk/SiteLogoThunk";

let initState = {
	reload: false,
	SiteLogoData: {},
	error: null,
};

const SiteLogoReducer = createSlice({
	name: "SiteLogo",

	initialState: initState,
	reducers: {},
	extraReducers: (builder) => {
		builder

			.addCase(UpdateSiteLogoThunk.pending, (state) => {
				state.reload = true;
			})
			.addCase(UpdateSiteLogoThunk.fulfilled, (state, action) => {
				state.reload = false;
				state.SiteLogoData = action.payload.data;
				localStorage.setItem("siteLogo", action.payload.data.site_logo);
				localStorage.setItem("footerLogo", action.payload.data.footer_logo);
			})
			.addCase(UpdateSiteLogoThunk.rejected, (state, action) => {
				state.reload = false;
				state.error = action.payload;
			})

			.addCase(SiteLogoThunk.pending, (state) => {
				state.reload = true;
			})
			.addCase(SiteLogoThunk.fulfilled, (state, action) => {
				state.reload = false;
				state.SiteLogoData = action.payload.data;
			})
			.addCase(SiteLogoThunk.rejected, (state, action) => {
				state.reload = false;
				state.error = action.error.message;
			});
	},
});
export default SiteLogoReducer.reducer;
