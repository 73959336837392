import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const AboutUsThunk = createAsyncThunk(
	"AboutUs/AboutUsThunk",

	async (_, ThunkApi) => {
		let { rejectWithValue } = ThunkApi;
		try {
			let res = await axios.get(`api/admin/about_us`);

			return res.data;
		} catch (error) {
			return rejectWithValue(error.response.data?.data);
		}
	}
);
