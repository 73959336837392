import React from "react";
import { LanguagesTaps, PageHeader, TextEditor } from "../../../Components";

import { VscSaveAs } from "react-icons/vsc";
import { AddNewPartners, EditPartner, PartnerBox } from "..";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

const Partners = () => {
	const { tapLanguage } = useSelector((state) => state.LanguageTapsReducer);
	const location = useLocation();
	const pathName = location.pathname.slice(1);
	return (
		<>
			<Helmet>
				<title>لوحة تحكم راج | شركائنا</title>
			</Helmet>
			<section className=''>
				<PageHeader
					addBtn={"اضافه شريك جديد"}
					navigateTo={"/add-new-partner"}
					currentPage={"شركائنا"}
					parentPage={null}
					description={
						"بامكانك اضافه وصف للصفحه بالاضافه الي اضافه العديد من الشركاء "
					}
				/>

				{/* Sector solution table*/}
				<section className=''>
					<h4 className='text-lg font-medium'> الشركاء الحاليين</h4>
				</section>

				{/* Partners Content */}
				<PartnerBox />

				{pathName === "add-new-partner" && <AddNewPartners />}
				{pathName === "edit-partner" && <EditPartner />}
			</section>
		</>
	);
};

export default Partners;
