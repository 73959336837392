import * as React from "react";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";

// icons
import { MdDelete } from "react-icons/md";
import { FiEdit } from "react-icons/fi";

// components
import TablePagination from "./tablePagination/TablePagination";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Avatar } from "@mui/material";
import EmptyData from "../EmptyData/EmptyData";

import AlertDialog from "../AlertDialog/AlertDialog";
import { AllProjectsThunk } from "../../RTK/Thunk/AllProjectsThunk";
import { DeleteProjectThunk } from "../../RTK/Thunk/DeleteProjectThunk";
import { AllSectorSolutionsThunk } from "../../RTK/Thunk/AllSectorSolutionsThunk";
import { DeleteSectorSolutionThunk } from "../../RTK/Thunk/DeleteSectorSolutionThunk";
import { Loader } from "../loader";

// -----------------------------------------------------------------

// table head cells
const headCells = [
	{
		id: "orderId",
		label: "م",
		align: "center",
	},
	{
		id: "name",
		align: "center",
		label: "صورة الخدمة",
	},
	{
		id: "message",
		align: "center",
		label: " عنوان الخدمة ",
	},

	{
		id: "des",
		align: "center",
		label: " وصف الخدمة ",
	},

	{
		id: "actions",
		align: "center",
		label: "الاجراء",
	},
];
// -----------------------------------------------------------------

function EnhancedTableHead(props) {
	return (
		<TableHead
			sx={{
				backgroundColor: "#ffc368",
				border: "none",
				height: "48px",

				"& .MuiTableCell-root": {
					fontFamily: "Tajawal",
					borderBottom: "0",
					padding: "0",
					color: "#315ca7",
					fontSize: "16px",
				},
			}}>
			<TableRow>
				{headCells.map((headCell) => (
					<TableCell key={headCell.id} align={headCell.align} padding={"none"}>
						<TableSortLabel
							sx={{
								"& .MuiSvgIcon-root": { display: "none" },
							}}>
							{headCell.label}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

const SectorSolutionsTable = () => {
	const location = useLocation();
	const pathName = location.pathname.slice(1);
	const navigate = useNavigate();
	const dispatch = useDispatch(false);

	const [deleteId, setDeleteId] = React.useState(0);
	const [openAlert, setOpenAlert] = React.useState(false);
	const [pageTarget, setPageTarget] = React.useState(1);

	const { sectorSolutionsData, currentPage, lastPage, reload } = useSelector(
		(state) => state.SectorSolutionsReducer
	);

	/** get contact data */
	React.useEffect(() => {
		if (pathName === "sector-solutions") {
			dispatch(AllSectorSolutionsThunk({ page: pageTarget }));
		}
	}, [pathName, pageTarget]);

	// ------------------------------------------------------
	const handleDeleteContact = (id) => {
		dispatch(
			DeleteSectorSolutionThunk({
				id: id,
			})
		)
			.unwrap()
			.then((data) => {
				dispatch(AllSectorSolutionsThunk({ page: pageTarget }));
			})
			.catch((error) => {
				// handle error here
				// toast.error(error, {
				// 	theme: "light",
				// });
			});
	};
	return reload ? (
		<section className=' h-full flex justify-center items-center pt-40'>
			<Loader />
		</section>
	) : (
		<Box className=' shadow-md border-[1px] border-slate-100 rounded-lg '>
			<Paper
				sx={{
					width: "100%",
					mb: 2,
					boxShadow: "none",
				}}>
				<TableContainer>
					<Table>
						<EnhancedTableHead />
						<TableBody
							sx={{
								"& .MuiTableCell-root": {
									fontFamily: "Tajawal",
									borderBottom: "1px solid #f1f8fb",
								},
							}}>
							{sectorSolutionsData?.length === 0 ? (
								<TableRow>
									<TableCell colSpan={6} sx={{ borderBottom: "none" }}>
										<EmptyData />
									</TableCell>
								</TableRow>
							) : (
								sectorSolutionsData?.map((row, index) => {
									return (
										<TableRow
											hover
											key={row?.id}
											sx={{
												"&.MuiTableRow-root.MuiTableRow-hover": {
													"&:hover": {
														backgroundColor: "#f1f8fb",
													},
												},
												"&.MuiTableRow-root.Mui-selected": {
													backgroundColor: "#f1f8fb",
												},
											}}>
											<TableCell align='center'>
												{(index + 1).toLocaleString("en-US", {
													minimumIntegerDigits: 2,
													useGrouping: false,
												})}
											</TableCell>

											<TableCell align='center'>
												<Avatar size='large' alt='' src={row?.image} />
											</TableCell>

											<TableCell align='center'>{row?.title}</TableCell>

											<TableCell align='center'>
												<div
													dangerouslySetInnerHTML={{
														__html: row?.description,
													}}
													className='max-w-[360px] text-center text-[14px] font-normal table-description'
												/>
											</TableCell>

											<TableCell align='right'>
												<div className='flex justify-center items-center gap-2'>
													<MdDelete
														onClick={() => {
															setOpenAlert(true);
															setDeleteId(row.id);
														}}
														title='حذف'
														className='w-6 h-6'
														style={{ color: "#E62237", cursor: "pointer" }}
													/>

													<FiEdit
														title='تعديل'
														className='w-5 h-5'
														onClick={() => {
															navigate(`/edit-sector/${row?.id}`);
														}}
														style={{ color: "#faa726", cursor: "pointer" }}
													/>
												</div>
											</TableCell>
										</TableRow>
									);
								})
							)}
						</TableBody>
					</Table>
				</TableContainer>

				{/* table Pagination*/}
				{sectorSolutionsData?.length !== 0 && (
					<TablePagination
						current_page={currentPage}
						count={lastPage}
						setPageTarget={setPageTarget}
					/>
				)}

				<AlertDialog
					open={openAlert}
					setOpen={setOpenAlert}
					handleDelete={handleDeleteContact}
					deleteId={deleteId}
					setDeleteId={setDeleteId}
				/>
			</Paper>
		</Box>
	);
};

export default SectorSolutionsTable;
