import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// Icons
import { VscSaveAs } from "react-icons/vsc";
import { FaSnapchat } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FiInstagram } from "react-icons/fi";
import { IoLogoYoutube } from "react-icons/io";
import { FaFacebook } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { PageHeader } from "../../../Components";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { SocialLinksThunk } from "../../../RTK/Thunk/SocialLinksThunk";
import { StoreAndUpdateSocialLinksThunk } from "../../../RTK/Thunk/StoreAndUpdateSocialLinksThunk";

const SocialMediaAccounts = () => {
	const location = useLocation();
	const pathName = location.pathname.slice(1);
	const navigate = useNavigate();
	const dispatch = useDispatch(false);

	const { socialLinkData } = useSelector((state) => state.SocialLinksReducer);

	const [socialAccounts, setSocialAccounts] = useState({
		snapChat: "",
		twitter: "",
		instagram: "",
		youtube: "",
		facebook: "",
		tiktok: "",
	});

	useEffect(() => {
		if (socialLinkData) {
			setSocialAccounts({
				...socialAccounts,
				snapChat: socialLinkData?.snapChat,
				twitter: socialLinkData?.twitter,
				instagram: socialLinkData?.instagram,
				youtube: socialLinkData?.youtube,
				facebook: socialLinkData?.facebook,
				tiktok: socialLinkData?.tiktok,
			});
		}
	}, [socialLinkData]);

	/** get contact data */
	React.useEffect(() => {
		if (pathName === "social-media-accounts") {
			dispatch(SocialLinksThunk());
		}
	}, [pathName]);

	// =====validation===========
	const schema = Yup.object().shape({
		snapChat: Yup.string().url("برجاء كتابه الرابط بشكل صحيح"),
		twitter: Yup.string().url("برجاء كتابه الرابط بشكل صحيح"),
		instagram: Yup.string().url("برجاء كتابه الرابط بشكل صحيح"),
		youtube: Yup.string().url("برجاء كتابه الرابط بشكل صحيح"),
		facebook: Yup.string().url("برجاء كتابه الرابط بشكل صحيح"),
		tiktok: Yup.string().url("برجاء كتابه الرابط بشكل صحيح"),
	});

	const {
		handleSubmit,
		register,
		reset,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
		mode: "all",
		defaultValues: {
			snapChat: "",
			twitter: "",
			instagram: "",
			youtube: "",
			facebook: "",
			tiktok: "",
		},
	});

	useEffect(() => {
		reset(socialAccounts);
	}, [socialAccounts, reset]);

	/** handle submit  */
	const onSubmit = async (data) => {
		try {
			await dispatch(StoreAndUpdateSocialLinksThunk(data))
				.unwrap()
				.then((data) => {
					navigate("/social-media-accounts");
				});
		} catch (error) {
			// Handle errors here
			console.error("Error updating profile data:", error);
		}
	};

	return (
		<section className=' border-[1px] border-slate-100 shadow-lg rounded-md p-8 '>
			<section className='mb-12'>
				<PageHeader
					currentPage={"صفحات التواصل الاجتماعي"}
					parentPage={null}
					isNested={true}
					description={
						"يمكنك  اضافه الروابط الخاصه بجميع وسائل التواصل الاجتماعي المختلفه"
					}
				/>
			</section>
			<form className='w-full' onSubmit={handleSubmit(onSubmit)}>
				{/* Snap chat */}
				<section className='mb-8'>
					<h4 className='flex justify-start items-center text-main_color gap-1 mb-2 text-lg font-normal '>
						<FaSnapchat className='w-5  text-second_color' />
						سناب شات
					</h4>
					<div
						className={`w-full h-14 border-[1px] border-gray-200 hover:border-second_color focus:border-second_color rounded-lg flex justify-start items-center gap-2 bg-white  px-5`}>
						<input
							type='url'
							name='snapchat'
							dir='ltr'
							{...register("snapchat")}
							placeholder='https://web.snapchat.com'
							className='w-full h-full bg-transparent outline-none placeholder:text-gray-300 text-[15px] font-normal text-black'
						/>

						<FaSnapchat className=' text-[20px] text-second_color opacity-30' />
					</div>

					{errors?.snapchat && errors?.snapchat.message ? (
						<div
							style={{
								width: "100%",
								color: "red",
								fontSize: "15px",
								marginTop: "5px",
							}}>
							{errors?.snapchat.message}
						</div>
					) : null}
				</section>

				{/* twitter */}
				<section className='mb-8'>
					<h4 className='flex justify-start items-center text-main_color gap-1 mb-2 text-lg font-normal '>
						<FaSquareXTwitter className='w-5  text-second_color' />
						تويتر
					</h4>
					<div
						className={`w-full h-14 border-[1px] border-gray-200 hover:border-second_color focus:border-second_color rounded-lg flex justify-start items-center gap-2 bg-white  px-5`}>
						<input
							type='url'
							name='twitter'
							dir='ltr'
							{...register("twitter")}
							placeholder='https://www.twitter.com'
							className='w-full h-full bg-transparent outline-none placeholder:text-gray-300 text-[15px] font-normal text-black'
						/>

						<FaSquareXTwitter className=' text-[20px] text-second_color opacity-30' />
					</div>

					{errors?.twitter && errors?.twitter.message ? (
						<div
							style={{
								width: "100%",
								color: "red",
								fontSize: "15px",
								marginTop: "5px",
							}}>
							{errors?.twitter.message}
						</div>
					) : null}
				</section>

				{/* instagram */}
				<section className='mb-8'>
					<h4 className='flex justify-start items-center text-main_color gap-1 mb-2 text-lg font-normal '>
						<FiInstagram className='w-5  text-second_color' />
						انستجرام
					</h4>
					<div
						className={`w-full h-14 border-[1px] border-gray-200 hover:border-second_color focus:border-second_color rounded-lg flex justify-start items-center gap-2 bg-white  px-5`}>
						<input
							type='url'
							name='instagram'
							dir='ltr'
							{...register("instagram")}
							placeholder='https://www.instagram.com'
							className='w-full h-full bg-transparent outline-none placeholder:text-gray-300 text-[15px] font-normal text-black'
						/>

						<FiInstagram className=' text-[20px] text-second_color opacity-30' />
					</div>

					{errors?.instagram && errors?.instagram.message ? (
						<div
							style={{
								width: "100%",
								color: "red",
								fontSize: "15px",
								marginTop: "5px",
							}}>
							{errors?.instagram.message}
						</div>
					) : null}
				</section>

				{/* youtube */}
				<section className='mb-8'>
					<h4 className='flex justify-start items-center text-main_color gap-1 mb-2 text-lg font-normal '>
						<IoLogoYoutube className='w-5  text-second_color' />
						يوتيوب
					</h4>
					<div
						className={`w-full h-14 border-[1px] border-gray-200 hover:border-second_color focus:border-second_color rounded-lg flex justify-start items-center gap-2 bg-white  px-5`}>
						<input
							type='url'
							name='youtube'
							dir='ltr'
							{...register("youtube")}
							placeholder='https://www.youtube.com'
							className='w-full h-full bg-transparent outline-none placeholder:text-gray-300 text-[15px] font-normal text-black'
						/>

						<IoLogoYoutube className=' text-[20px] text-second_color opacity-30' />
					</div>

					{errors?.youtube && errors?.youtube.message ? (
						<div
							style={{
								width: "100%",
								color: "red",
								fontSize: "15px",
								marginTop: "5px",
							}}>
							{errors?.youtube.message}
						</div>
					) : null}
				</section>

				{/* facebook */}
				<section className='mb-8'>
					<h4 className='flex justify-start items-center text-main_color gap-1 mb-2 text-lg font-normal '>
						<FaFacebook className='w-5  text-second_color' />
						فيسبوك
					</h4>
					<div
						className={`w-full h-14 border-[1px] border-gray-200 hover:border-second_color focus:border-second_color rounded-lg flex justify-start items-center gap-2 bg-white  px-5`}>
						<input
							type='url'
							name='facebook'
							dir='ltr'
							{...register("facebook")}
							placeholder='https://www.facebook.com'
							className='w-full h-full bg-transparent outline-none placeholder:text-gray-300 text-[15px] font-normal text-black'
						/>

						<FaFacebook className=' text-[20px] text-second_color opacity-30' />
					</div>

					{errors?.facebook && errors?.facebook.message ? (
						<div
							style={{
								width: "100%",
								color: "red",
								fontSize: "15px",
								marginTop: "5px",
							}}>
							{errors?.facebook.message}
						</div>
					) : null}
				</section>

				{/* Tiktok */}
				<section className='mb-8'>
					<h4 className='flex justify-start items-center text-main_color gap-1 mb-2 text-lg font-normal '>
						<FaTiktok className='w-5  text-second_color' />
						تيك توك
					</h4>
					<div
						className={`w-full h-14 border-[1px] border-gray-200 hover:border-second_color focus:border-second_color rounded-lg flex justify-start items-center gap-2 bg-white  px-5`}>
						<input
							type='url'
							name='tiktok'
							dir='ltr'
							{...register("tiktok")}
							placeholder='https://www.tiktok.com'
							className='w-full h-full bg-transparent outline-none placeholder:text-gray-300 text-[15px] font-normal text-black'
						/>

						<FaTiktok className=' text-[20px] text-second_color opacity-30' />
					</div>

					{errors?.tiktok && errors?.tiktok.message ? (
						<div
							style={{
								width: "100%",
								color: "red",
								fontSize: "15px",
								marginTop: "5px",
							}}>
							{errors?.tiktok.message}
						</div>
					) : null}
				</section>

				{/* page content */}
				<section>
					<button
						type='submit'
						className='flex justify-center items-center text-lg font-medium text-white px-8 h-14  bg-main_color hover:bg-second_color gap-2 rounded-md shadow-md '>
						<span> حفظ الاعدادات</span>
						<VscSaveAs />
					</button>
				</section>
			</form>
		</section>
	);
};

export default SocialMediaAccounts;
