import React from "react";
import { ContactUsTable, PageHeader } from "../../../Components";

import { MessageDetailsModal } from "..";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

const ContactUs = () => {
	const location = useLocation();
	const pathName = location.pathname.slice(1);

	return (
		<>
			<Helmet>
				<title>لوحة تحكم راج | تواصل معنا</title>
			</Helmet>
			<section className=''>
				<PageHeader
					currentPage={"تواصل معنا"}
					parentPage={null}
					isNested={true}
					description={
						"هذا القسم يحتوي علي جميع الاستفسارات الخاصه بخدمه العملاء"
					}
				/>

				{/* Sector solution table*/}
				<section className=''>
					<h4 className='text-lg font-medium'> الاستفسارات الحالية</h4>
					<ContactUsTable />
				</section>

				{/* Page Content */}
				{pathName === "message-details" && <MessageDetailsModal />}
			</section>
		</>
	);
};

export default ContactUs;
