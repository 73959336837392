import React from "react";

const EmptyData = () => {
	return (
		<h4 className=' w-full flex justify-center text-slate-800 text-lg font-normal '>
			لا توجد بيانات!
		</h4>
	);
};

export default EmptyData;
